import { useQuery } from '@tanstack/react-query';

import { getColeccionMovimientosTrituracion } from '../services/movimientoTrituracionSecundaria.service';

export default function useColeccionTrituracionSecundaria({ fecha, turnoId, areaTrituracion }) {
  const coleccionTrituracionSecundaria = useQuery(
    ['coleccion-trituracion-secundaria', fecha, turnoId, areaTrituracion],
    () => getColeccionMovimientosTrituracion({ fecha, turnoId, areaTrituracion }),
    { enabled: Boolean((fecha && turnoId) || areaTrituracion), retry: 0, cacheTime: 0 },
  );

  return coleccionTrituracionSecundaria;
}
