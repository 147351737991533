import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import FondoLogin from './assets/images/fondo.jpg';
import FooterComponent from './components/Footer';
import NavMenuComponent from './components/NavMenu';
import ProtectedRoute from './components/ProtectedRoute';
import { queryClient } from './config/queryClient';
import { ROUTES } from './constants/routes';
import SesionContext from './context/DataContext';
import { IDataContext, IUsuarioSesionContext } from './models/IDataContext';
import BaseDeDatos from './pages/BaseDeDatos';
import Calidad from './pages/Calidad';
import CambiarContrasena from './pages/CambiarContrasena';
import CatalogosPage from './pages/Catalogos/Catalogos';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/Home';
import MovimientoMineral from './pages/MovimientoMineral';
import NoEncontradoPage from './pages/NoEncontrado';
import Pladial from './pages/Pladial';
import RegistroUsuarioPage from './pages/RegistroUsuario';
import RegistroViajes from './pages/RegistroViajes/RegistroViajes';
import Registros from './pages/Registros/Registros';
import TrituracionSecundaria from './pages/TrituracionSecundaria';
import UsuariosPage from './pages/Usuarios';
import Voladuras from './pages/Voladuras';

function App() {
  const [dataUser, setDataUser] = useState<IUsuarioSesionContext>({
    usuario: JSON.parse(localStorage.getItem('credenciales'))?.usuario,
    roles: JSON.parse(localStorage.getItem('credenciales'))?.roles,
    area: JSON.parse(localStorage.getItem('credenciales'))?.area,
    areaId: JSON.parse(localStorage.getItem('credenciales'))?.areaId,
  });
  const contextData: IDataContext = {
    dataUser,
    setDataUser,
  };
  const location = useLocation();
  const isLoggedIn = !!dataUser.usuario;

  const fondoLogin = () => {
    if (!isLoggedIn && location.pathname === ROUTES.ROOT) {
      return {
        minHeight: '100vh',
        backgroundImage: `url('${FondoLogin}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };
    }

    return { minHeight: '100vh' };
  };

  const rutaRedireccion = () => {
    return dataUser.roles
      .map((rol: string) => rol.toLowerCase())
      .includes('UsrTrituracionSecundaria'.toLowerCase())
      ? ROUTES.REGISTROS
      : dataUser.roles.map((rol: string) => rol.toLowerCase()).includes('Supervisor'.toLowerCase())
      ? ROUTES.PLADIAL
      : location.state?.from || ROUTES.DASHBOARD;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <SesionContext.Provider value={contextData}>
        <main className="d-flex flex-column justify-content-between" style={fondoLogin()}>
          {isLoggedIn && <NavMenuComponent />}
          <div className="flex-grow-1">
            <Routes>
              <Route
                element={isLoggedIn ? <Navigate to={rutaRedireccion()} /> : <LoginPage />}
                path={ROUTES.ROOT}
              />
              <Route element={<ProtectedRoute title="Dashboard" />} path={ROUTES.DASHBOARD}>
                <Route element={<Dashboard />} index />
              </Route>
              <Route element={<ProtectedRoute title="Usuarios" />} path={ROUTES.USUARIOS}>
                <Route element={<UsuariosPage />} index />
                <Route
                  element={<RegistroUsuarioPage />}
                  path={`${ROUTES.REGISTRAR_USUARIO}/:tipo`}
                />
                <Route element={<RegistroUsuarioPage />} path={`${ROUTES.EDITAR_USUARIO}/:id/:tipo`} />
              </Route>
              <Route element={<ProtectedRoute title="Catalogos" />} path={ROUTES.CATALOGOS}>
                <Route element={<CatalogosPage />} index />
              </Route>
              <Route element={<ProtectedRoute title="Registros" />} path={ROUTES.REGISTROS}>
                <Route element={<Registros />} index />
              </Route>
              <Route
                element={<ProtectedRoute title="Registro viajes" />}
                path={ROUTES.REGISTRO_VIAJES}
              >
                <Route element={<RegistroViajes />} index />
              </Route>
              <Route element={<ProtectedRoute title="Pladial" />} path={ROUTES.PLADIAL}>
                <Route element={<Pladial />} index />
              </Route>
              <Route
                element={<ProtectedRoute title="Trituración Secundaria" />}
                path={ROUTES.TRITURACION_SECUNDARIA}
              >
                <Route element={<TrituracionSecundaria />} index />
              </Route>
              <Route
                element={<ProtectedRoute title="Movimiento mineral" />}
                path={ROUTES.MOVIMIENTO_MINERAL}
              >
                <Route element={<MovimientoMineral />} index />
              </Route>
              <Route element={<ProtectedRoute title="Voladuras" />} path={ROUTES.VOLADURAS}>
                <Route element={<Voladuras />} index />
              </Route>
              <Route element={<ProtectedRoute title="Cambiar contraseña" />} path={ROUTES.CAMBIAR_CONTRASENA}>
                <Route element={<CambiarContrasena />} index />
              </Route>
              <Route element={<ProtectedRoute title="Base de datos" />} path={ROUTES.BASE_DE_DATOS}>
                <Route element={<BaseDeDatos />} index />
              </Route>
              <Route element={<ProtectedRoute title="Calidad" />} path={ROUTES.CALIDAD}>
                <Route element={<Calidad />} index />
              </Route>
              <Route element={<NoEncontradoPage />} path={ROUTES.NO_ENCONTRADO} />
            </Routes>
          </div>
          <FooterComponent />
        </main>
      </SesionContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
