import { useQuery } from '@tanstack/react-query';

import { reporteAreaTrituracionSecundaria } from '../services/reportes.service';

export default function useReporteTrituracionSecundaria({ fecha, turnoId, areaTrituracion }) {
  const reporteTrituracionSecundaria = useQuery(
    ['reporte-trituracion-secundaria', fecha, turnoId, areaTrituracion],
    () => reporteAreaTrituracionSecundaria({ fecha, turnoId, areaTrituracion }),
    { enabled: false, retry: 0, cacheTime: 0, refetchOnWindowFocus: false },
  );

  return reporteTrituracionSecundaria;
}
