import { useQuery } from '@tanstack/react-query';

import { getDepositoMineralService } from '../services/depositoMineral.service';

const useDepositoMineral = options => {
  return useQuery(['deposito'], getDepositoMineralService, {
    staleTime: Infinity,
    ...options,
  });
};

export default useDepositoMineral;
