import { MutationOptions, QueryClient, UseQueryOptions } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      // staleTime: 1000 * 60 * 5, // 5 minutos de caché
    },
  },
});

/**
 * Este tipo se utiliza para poder pasarle configuración a un hook de query de React Query.
 */
export type QueryConfig<T = unknown> = Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>;
/**
 * Este tipo se utiliza para poder pasarle configuración a un hook de mutation de React Query.
 */
export type MutationConfig<T = unknown> = Omit<MutationOptions<T>, 'mutationFn'>;
