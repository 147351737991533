import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Form, Button, Modal, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import useAreasTrituracion from '../../hooks/useAreasTrituracion';
import useError from '../../hooks/useError';
import { ICamiones } from '../../models/interfaces/catalogos/ICatalogos';
import { EditarModalType } from '../../models/types/modal.type';
import { addCamionService, editCamionService } from '../../services/camiones.service';
import { registroCamionValidationSchema } from '../../utils/validations/registro-camion.validation';

export default function EditarCamionModal(props: EditarModalType) {
  const { estadoModalCatalogo, onClose, detalle } = props;
  const isEdit = detalle.id;
  const { ErrorAlert, setError } = useError();
  const { data: areasTrituracion } = useAreasTrituracion({
    onError: e => setError(e),
  });
  const queryClient = useQueryClient();
  const { mutate } = useMutation(isEdit ? editCamionService : addCamionService, {
    onError: e => setError(e),
    onSuccess: () => {
      queryClient.invalidateQueries(['camiones']);
      onClose();
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<ICamiones>({
    resolver: yupResolver(registroCamionValidationSchema),
  });

  const EditarCamion = async (requestData: ICamiones) => {
    mutate(requestData);
  };

  useEffect(() => {
    if (estadoModalCatalogo) {
      reset(isEdit ? detalle : undefined);
    } else {
      reset({ numCamion: '', areaTrituracionEntityId: 0, volumen: 0, id: 0 });
    }
    setError('');
  }, [estadoModalCatalogo]);

  return (
    <Modal show={estadoModalCatalogo} onHide={onClose} centered>
      <ErrorAlert />
      <form onSubmit={handleSubmit(EditarCamion)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Editar' : 'Nuevo'} Camión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Col xs={12}>
              <Form.Group className="text-left p-2" controlId="tipo-camion">
                <Form.Label>Tipo Camión</Form.Label>
                <Form.Select {...register('areaTrituracionEntityId')}>
                  {areasTrituracion?.map(({ id, descripcion }) => (
                    <option key={id} value={id}>
                      {descripcion}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.areaTrituracionEntityId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="text-left p-2" controlId="num-camion">
                <Form.Label>Num. Camión</Form.Label>
                <Form.Control
                  isInvalid={!!errors.numCamion}
                  placeholder="Ingresa el num. camión"
                  {...register('numCamion')}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.numCamion?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="text-left p-2" controlId="volumen">
                <Form.Label>Volumen</Form.Label>
                <Form.Control
                  isInvalid={!!errors.volumen}
                  placeholder="Ingresa el volumen"
                  {...register('volumen')}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.volumen?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="br-cg" variant="btn btn-outline-dark" onClick={onClose}>
            Cancelar
          </Button>
          <Button className="text-white" variant="secondary" type="submit">
            {isEdit ? 'Editar' : 'Guardar'} Camión
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
