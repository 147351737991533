import { ReactElement, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import useData from '../hooks/useData';
import useRole from '../hooks/useRole';

interface ProtectedRouteTypes {
  children?: ReactElement;
  title?: string;
}

export default function ProtectedRoute({ children, title }: ProtectedRouteTypes) {
  const { dataUser } = useData();
  const location = useLocation();
  const esRoleTrituracion = useRole('UsrTrituracionSecundaria');
  const esAdministrador = useRole('Administrador');

  useEffect(() => {
    document.title = `Almacén de Minerales${title ? ` - ${title}` : ''}`;
  }, [title]);

  if (dataUser.usuario === null) {
    return <Navigate state={{ from: location.pathname }} to={ROUTES.ROOT} />;
  }

  //i El usuario con role de trituración secundaria no tendra mas accesos
  if (esRoleTrituracion && title.toLowerCase() !== 'registros' && title.toLowerCase() !== 'cambiar contraseña') {
    return <Navigate state={{ from: location.pathname }} to={ROUTES.REGISTROS} />;
  }

  //i El administrador no puede entrar a registros de trituración secundaria
  if (esAdministrador && title.toLowerCase() === 'registros') {
    return <Navigate state={{ from: location.pathname }} to={ROUTES.DASHBOARD} />;
  }

  return children ? children : <Outlet />;
}
