import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import { TablaCalidadesTrituracionSecundaria } from './components/TablaCalidadesTrituracionSecundaria';

export const CalidadesTrituracionSecundaria: FC = () => {
  return (
    <Row className="justify-content-around gap-4 flex-nowrap">
      <Col xs={6}>
        <TablaCalidadesTrituracionSecundaria area="GDI" />
      </Col>

      <Col xs={6}>
        <TablaCalidadesTrituracionSecundaria area="EMD" />
      </Col>
    </Row>
  );
};
