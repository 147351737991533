import * as Yup from 'yup';

import { ErrorMessages } from '../../constants/errorMessages';

export const EditarMovimientoTonelajeValidationSchema = Yup.object().shape({
  camionId: Yup.number().min(1, ErrorMessages.Required),
  depositoMineralId: Yup.number().min(1, ErrorMessages.Required),
  toneladas: Yup.number().min(1, ErrorMessages.Required),
  viajes: Yup.number().min(1, ErrorMessages.Required),
});
