import { useQuery } from '@tanstack/react-query';

import { QueryConfig } from '../config/queryClient';
import { IAreaTrituracion } from '../models/area-trituracion.model';
import { getAreasTritService } from '../services/usuarios.service';

const useAreasTrituracion = (config?: QueryConfig<IAreaTrituracion[]>) => {
  return useQuery<IAreaTrituracion[]>(['areas-trituracion'], getAreasTritService, config);
};

export default useAreasTrituracion;
