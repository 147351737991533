export const ErrorMessages = {
  Required: 'El campo es obligatorio',
  AtLeastOne: 'Se debe seleccionar al menos una opción.',
  InvalidValue: (condicion: string, subcadena: string = 'valor') =>
    `El ${subcadena} no es válido, deben ser ${condicion}`,
  LengthValue: (lengthVal: number) =>
    `El valor debe tener ${lengthVal} caracter${lengthVal > 1 ? 'es' : ''}`,
  MinValue: (lengthVal: number) =>
    `El valor debe ser mayor a ${lengthVal} caracter${lengthVal > 1 ? 'es' : ''}`,
  InvalidEmail: 'El correo no es válido',
  HigherTime: 'Hora fin debe ser mayor a la de inicio',
  Match: fieldToMatch => `El campo debe coincidir con ${fieldToMatch}`,
  MinNumberValue: (minVal: number) => `El valor minimo debe ser ${minVal}`,
  InvalidRegexValue: 'El valor no es válido',
};
