import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { Button, Col, Container, FormControl, FormGroup, Row } from 'react-bootstrap';
import Feedback from 'react-bootstrap/esm/Feedback';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ErrorMessages } from '../../../constants/errorMessages';
import {
  useAgregarCalidadesTrituracionSecundariaMutation,
  useAreaDeTrituracionQuery,
  useCalidadesTrituracionSecundariaQuery,
} from '../../../hooks/trituracion-secundaria.hooks';
import useError from '../../../hooks/useError';

const calidadesSchema = yup.object({
  calidades: yup.object({
    feMag: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    feTot: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    silice: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    azufre: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    calcio: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    fosforo: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    mgo: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    al203: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    kwhtMol: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
    rw: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(ErrorMessages.Required),
  }),
  fechaDeActualizacion: yup.string().nullable().required(ErrorMessages.Required),
});

type CalidadesFormType = typeof calidadesSchema.__outputType;

type TablaCalidadesTrituracionSecundariaProps = {
  area: 'GDI' | 'EMD';
};

export const TablaCalidadesTrituracionSecundaria: FC<TablaCalidadesTrituracionSecundariaProps> = ({
  area,
}) => {
  const [editando, setEditando] = useState(false);
  const { ErrorAlert, setError } = useError();

  const { data: response, isLoading: loadingCalidades } = useCalidadesTrituracionSecundariaQuery(
    { area },
    { enabled: !!area, onError: setError },
  );
  const { data: areaTrituracion, isLoading: loadingArea } = useAreaDeTrituracionQuery(area, {
    enabled: !!area,
  });

  const { mutateAsync: agregarCalidades } = useAgregarCalidadesTrituracionSecundariaMutation();

  console.log('🚀 ~ areaTrituracion:', areaTrituracion);
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(calidadesSchema),
    defaultValues: {
      calidades: {
        feMag: undefined,
        feTot: undefined,
        silice: undefined,
        azufre: undefined,
        calcio: undefined,
        fosforo: undefined,
        mgo: undefined,
        al203: undefined,
        kwhtMol: undefined,
        rw: undefined,
      },
      fechaDeActualizacion: format(new Date(), 'yyyy-MM-dd'),
    },
  });

  async function saveChanges(formValues: CalidadesFormType) {
    try {
      console.log({
        areaTrituracionId: areaTrituracion?.id,
        ...formValues,
      });
      await agregarCalidades({
        areaTrituracionId: areaTrituracion?.id,
        ...formValues,
      });
      setEditando(false);
    } catch (err) {
      setError(err);
    }
  }

  const vistaCargando = loadingArea || loadingCalidades;

  return (
    <Container>
      <ErrorAlert />
      <Row className="align-items-center mb-3">
        <Col className="text-center" xs={6}>
          <strong>Área Trituración</strong>
        </Col>
        <Col className="text-center" xs={6}>
          <Row className="align-items-center">
            <Col xs={6}>
              <strong>{area}</strong>
            </Col>
            <Col className="text-right" xs={6}>
              {editando ? (
                <Button
                  className="d-inline-flex align-items-center"
                  disabled={isSubmitting}
                  onClick={handleSubmit(saveChanges)}
                  type="button"
                  variant="secondary"
                >
                  Guardar <SaveIcon className="ms-1" fontSize="small" />
                </Button>
              ) : (
                <Button
                  className="d-inline-flex align-items-center"
                  disabled={vistaCargando}
                  onClick={() => setEditando(true)}
                  type="button"
                  variant="secondary"
                >
                  Editar <EditIcon className="ms-1" fontSize="small" />
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="py-3 px-2 mb-1 bg-body-secondary align-items-center">
        <Col className="text-center" xs={7}>
          <strong>Fecha de actualización</strong>
        </Col>
        <Col className="text-center" xs={5}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <FormGroup>
              <FormControl type="date" {...register('fechaDeActualizacion')} />
              <Feedback type="invalid">{errors.fechaDeActualizacion?.message}</Feedback>
            </FormGroup>
          ) : response?.fechaDeActualizacion ? (
            <strong>{format(new Date(response?.fechaDeActualizacion), 'dd MMM yyyy')}</strong>
          ) : (
            <small className="fst-italic">Aún no hay datos registrados.</small>
          )}
        </Col>
      </Row>

      <Row className="py-3 px-2 mb-1 bg-body-secondary">
        <Col xs={8}>
          <strong>Características Físicas y Químicas</strong>
        </Col>
        <Col className="text-center" xs={4}>
          <strong>%</strong>
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>%FeMag</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.feMag}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.feMag')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.feMag?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.feMag.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>FeTot</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.feTot}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.feTot')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.feTot?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.feTot.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>Silice (SiO2)</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.silice}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.silice')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.silice?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.silice.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>Azufre (S)</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.azufre}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.azufre')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.azufre?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.azufre.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>Calcio (Ca)</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.calcio}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.calcio')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.calcio?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.calcio.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>Fósforo (P)</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.fosforo}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.fosforo')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.fosforo?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.fosforo.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>Óxido Magnesio (MgO)</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.mgo}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.mgo')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.mgo?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.mgo.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>
            Alúmina (AL<sub>2</sub>O<sub>3</sub>)
          </strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.al203}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.al203')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.al203?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.al203.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>kwh/t Mol Aut</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.kwhtMol}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.kwhtMol')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.kwhtMol?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.kwhtMol.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>

      <Row className="py-2 px-2 mb-1 border align-items-center">
        <Col className="text-center" xs={8}>
          <strong style={{ lineHeight: '2.375rem' }}>RW</strong>
        </Col>
        <Col className="text-center" xs={4}>
          {vistaCargando ? (
            <Skeleton animation="wave" variant="rectangular" />
          ) : editando ? (
            <>
              <FormControl
                isInvalid={!!errors.calidades?.rw}
                max={100}
                min={0}
                step="0.001"
                type="number"
                {...register('calidades.rw')}
              />
              <Feedback className="text-start" type="invalid">
                {errors.calidades?.rw?.message.toString()}
              </Feedback>
            </>
          ) : (
            response?.calidades?.rw.toFixed(3) || (
              <small className="fst-italic">Aún no hay datos registrados.</small>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};
