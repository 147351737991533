import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

import { ReactComponent as BaseDeDatos } from '../assets/icons/database.svg';
import { ReactComponent as Voladuras } from '../assets/icons/earth-element.svg';
import { ReactComponent as Calidad } from '../assets/icons/guarantee.svg';
import { ReactComponent as Pladial } from '../assets/icons/info.svg';
import { ReactComponent as MovimientoMineral } from '../assets/icons/local-shipping.svg';
import { ReactComponent as Trituracion } from '../assets/icons/trituracion.svg';
import Logo from '../assets/images/logo.png';
import NavItemComponent from '../components/NavItem';
import { ROUTES } from '../constants/routes';
import useData from '../hooks/useData';
import useRole from '../hooks/useRole';
import { logoutService } from '../services/auth.service';

import '../styles/components/NavMenu.component.scss';

export default function NavMenu() {
  const { dataUser, setDataUser } = useData();

  return (
    <>
      <Navbar
        bg="primary"
        className="navmenu px-3"
        expand="lg"
        sticky="top"
        variant="dark"
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Brand as={Link} to={ROUTES.ROOT}>
            <img alt="Logo Peña colorada" height="57" src={Logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="navmenu__nav-items">
              <Nav>
                {useRole('Administrador') && (
                  <>
                    <Nav.Link as={Link} to={ROUTES.ROOT}>
                      Dashboard
                    </Nav.Link>
                    <Nav.Link as={Link} to={ROUTES.REGISTRO_VIAJES}>
                      Registro de Viajes
                    </Nav.Link>
                  </>
                )}
                {useRole('UsrTrituracionSecundaria') && (
                  <Nav.Link as={Link} to={ROUTES.REGISTROS}>
                    Trituración Secundaria
                  </Nav.Link>
                )}
                {useRole('Supervisor') && (
                  <Nav.Link as={Link} to={ROUTES.REGISTRO_VIAJES}>
                    Registro de Viajes
                  </Nav.Link>
                )}
              </Nav>
              <Nav>
                <NavDropdown
                  id="basic-nav-dropdown"
                  title={<NavItemComponent role={dataUser.roles[0]} usuario={dataUser.usuario} />}
                >
                  {useRole('Administrador') && (
                    <>
                      <NavDropdown.Item as={Link} to={ROUTES.USUARIOS}>
                        Usuarios
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={ROUTES.CATALOGOS}>
                        Catálogos
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                  )}
                  {useRole('UsrTrituracionSecundaria') && (
                    <NavDropdown.Item as={Link} to={ROUTES.CAMBIAR_CONTRASENA}>
                      Cambiar contraseña
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={() => logoutService(setDataUser)}>
                    Cerrar Sesión
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {useRole('Administrador') && (
        <header className="py-1 mb-4 border-bottom">
          <Nav className="container d-flex flex-wrap justify-content-between">
            <Nav.Link
              as={NavLink}
              className="secondary-nav-link align-items-center d-flex"
              to={ROUTES.PLADIAL}
            >
              <Pladial className="me-1" style={{ height: '2.25rem', width: '2.5rem' }} />
              Pladial
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="secondary-nav-link align-items-center d-flex"
              to={ROUTES.TRITURACION_SECUNDARIA}
            >
              <Trituracion className="me-1" style={{ height: '2.25rem', width: '2.5rem' }} />
              Trituración Secundaria
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="secondary-nav-link align-items-center d-flex"
              to={ROUTES.MOVIMIENTO_MINERAL}
            >
              <MovimientoMineral className="me-1" style={{ height: '2.25rem', width: '2.5rem' }} />
              Movimiento Mineral
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="secondary-nav-link align-items-center d-flex"
              to={ROUTES.VOLADURAS}
            >
              <Voladuras className="me-1" style={{ height: '2.25rem', width: '2.5rem' }} />
              Voladuras
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="secondary-nav-link align-items-center d-flex"
              to={ROUTES.CALIDAD}
            >
              <Calidad className="me-1" style={{ height: '2.25rem', width: '2.5rem' }} />
              Calidad
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="secondary-nav-link align-items-center d-flex"
              to={ROUTES.BASE_DE_DATOS}
            >
              <BaseDeDatos className="me-1" style={{ height: '2.25rem', width: '2.5rem' }} />
              Base de Datos
            </Nav.Link>
          </Nav>
        </header>
      )}
      {useRole('Supervisor') && (
        <header className="py-1 mb-4 border-bottom">
          <Nav.Link
            as={NavLink}
            className="secondary-nav-link align-items-center d-flex"
            to={ROUTES.PLADIAL}
          >
            <Pladial />
            Pladial
          </Nav.Link>
        </header>
      )}
    </>
  );
}
