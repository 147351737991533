import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.scss';
import { setAuthorizationHeaderInterceptors } from './utils/http';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// Set date-fns default options
setDefaultOptions({ locale: es });
setAuthorizationHeaderInterceptors();

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement,
);
