import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FechaTurno from '../components/FechaTurno';
import Loading from '../components/Loading';
import useError from '../hooks/useError';
import useFechaTurno from '../hooks/useFechaTurno';
import { TurnosEnum } from '../models/enums/turnos.enum';
import { putTrayectosViajesService } from '../services/trayectoCamion.service';
import { getViajesByDateService } from '../services/viajesCamion.service';
import '../styles/MovimientoMineral.scss';

export default function MovimientoMineral() {
  const { ErrorAlert, setError } = useError();
  const { fecha, turno } = useFechaTurno();
  const queryClient = useQueryClient();
  const [editarMovimientos, setEditarMovimientos] = useState<boolean>(false);

  const isWholeDay = useMemo(() => +turno === TurnosEnum.TodoElDia, [turno]);

  const { register, handleSubmit, setValue, unregister } = useForm({ shouldUnregister: true });
  const {
    data,
    isLoading: isLoadingViajes,
    isFetching: isFetchingViajes,
  } = useQuery(['movimiento-mineral', fecha, turno], () => getViajesByDateService(fecha, turno), {
    onError: e => setError(e),
    enabled: !!fecha,
  });
  const { mutateAsync: editarViajesAsync, isLoading: isLoadingEditarViajes } = useMutation(
    putTrayectosViajesService,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['movimiento-mineral', fecha, turno]);
      },
    },
  );

  const onSave = async values => {
    if (values) {
      const ids = Object.keys(values);
      const requestData = ids.map(id => ({ id: +id, cantidadesViajes: values[id] }));
      await editarViajesAsync({ cantidadesTrayectosViajes: requestData });
    }
  };

  const totalToneladasPorTrayecto = useMemo(() => {
    if (!data) return [];

    const tons = data.origenesDestinos.map<number>(origenDest =>
      data.trayectosOrigenDestino.reduce((total, trayecto) => {
        const trayectoCantidadViaje = trayecto.cantidadesViajes.find(
          viajes =>
            viajes.trayectoOrigen === origenDest.origen &&
            viajes.trayectoDestino === origenDest.destino,
        );

        if (!trayectoCantidadViaje?.cantidadViajes) {
          return total;
        }

        return total + trayectoCantidadViaje.cantidadViajes * trayecto.volumen;
      }, 0),
    );

    return tons;
  }, [data]);

  useEffect(() => {
    if (editarMovimientos) {
      data?.trayectosOrigenDestino?.forEach(trayectoOrigenDestino => {
        trayectoOrigenDestino?.cantidadesViajes?.forEach(viaje => {
          setValue(`${viaje.idViajesTrayecto}`, viaje.cantidadViajes);
        });
      });
    }
    return () => {
      unregister();
    };
  }, [editarMovimientos]);

  useEffect(() => {
    setEditarMovimientos(false);
  }, [fecha, turno, data]);

  return (
    <Container className="movimiento-mineral d-flex flex-column" fluid>
      {(isLoadingEditarViajes || isFetchingViajes || isLoadingViajes) && (
        <Loading mensaje="Cargando..." />
      )}
      <ErrorAlert />
      <FechaTurno showWholeDayOption />
      <form onSubmit={handleSubmit(onSave)}>
        <Row className="mt-0 mb-2 justify-content-end">
          {editarMovimientos && !isWholeDay && (
            <Col className="d-grid" md={3}>
              <Button type="submit">Guardar movimientos</Button>
            </Col>
          )}
          {!editarMovimientos && !isWholeDay && (
            <Col className="d-grid" md={3}>
              <Button onClick={() => setEditarMovimientos(old => !old)}>Editar movimientos</Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Table bordered>
              <thead>
                <tr className="primary-header">
                  <th className="camion text-white" rowSpan={2}>
                    Camión
                  </th>
                  <th>Origen</th>
                  {data?.origenesDestinos?.map((origenDestino, i) => (
                    <th key={i}>{origenDestino.origen}</th>
                  ))}
                </tr>
                <tr className="primary-header">
                  <th>Destino</th>
                  {data?.origenesDestinos?.map((origenDestino, i) => (
                    <th key={i}>{origenDestino.destino}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.trayectosOrigenDestino?.map((trayecto, i) => (
                  <tr key={i}>
                    <td>{trayecto.noCamion}</td>
                    <td />
                    {data.origenesDestinos.map((origenDestino, i) => {
                      const trayectoCantidadViaje = trayecto.cantidadesViajes.find(
                        viajes =>
                          viajes.trayectoOrigen === origenDestino.origen &&
                          viajes.trayectoDestino === origenDestino.destino,
                      );

                      return (
                        <td key={i}>
                          {editarMovimientos &&
                          !isWholeDay &&
                          trayectoCantidadViaje?.idViajesTrayecto ? (
                            <Col>
                              <Form.Control
                                type="number"
                                {...register(`${trayectoCantidadViaje.idViajesTrayecto}`, {
                                  valueAsNumber: true,
                                })}
                              />
                            </Col>
                          ) : trayectoCantidadViaje?.cantidadViajes ? (
                            `${trayectoCantidadViaje.cantidadViajes * trayecto.volumen}t (${
                              trayectoCantidadViaje.cantidadViajes
                            } viaje${trayectoCantidadViaje.cantidadViajes > 1 ? 's' : ''})`
                          ) : (
                            0
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}

                {data?.origenesDestinos.length ? (
                  <tr>
                    <td>
                      <strong>Total de tonelaje movido</strong>
                    </td>
                    <td />
                    {totalToneladasPorTrayecto.map((tons, i) => (
                      <td key={i}>
                        <strong>{tons} TONs</strong>
                      </td>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    <td className="text-center text-body-secondary fst-italic" colSpan={2}>
                      No existen movimientos registrados para la fecha y turno seleccionados.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
