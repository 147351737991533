import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';

import FechaTurno from '../components/FechaTurno';
import Loading from '../components/Loading';
import EditarPosicionador from '../components/modals/EditarPosicionador';
import useError from '../hooks/useError';
import useFechaTurno from '../hooks/useFechaTurno';
import { useVoladuras } from '../hooks/useVoladuras';
import { formatNumber } from '../utils/formats';

const NoResults = ({ colSpan = 8 }) => (
  <tr>
    <td colSpan={colSpan}>No hay voladuras para la fecha y turno seleccionados</td>
  </tr>
);

export default function Voladuras() {
  const [estadoModal, setEstadoModal] = useState<boolean>(false);
  const { fecha, turno } = useFechaTurno();
  const { ErrorAlert, setError } = useError();
  const { isLoading: isLoadingTurnos } = useQuery(['turnos']);
  const enabled = Boolean(fecha && turno);

  const { data: voladuras, isLoading: isLoadingVoladuras } = useVoladuras({
    fecha,
    turno,
    config: { enabled, retry: 2, onError: ex => setError(ex) },
  });

  const hasVoladuras = voladuras?.length > 0;

  const total = formatNumber(
    voladuras?.reduce((acc, current) => acc + current?.tonelajeAcumulado, 0)?.toFixed(2) ?? 0,
  );

  return (
    <Container className="d-flex flex-column" fluid>
      <Row>
        <Col xs={9}>
          <FechaTurno showWholeDayOption />
        </Col>
        <Col className="d-flex align-items-center justify-content-end" xs={3}>
          <Button onClick={() => setEstadoModal(true)}>Modificar Posicionador</Button>
        </Col>
      </Row>
      <ErrorAlert />
      {((isLoadingVoladuras && enabled) || isLoadingTurnos) && (
        <Loading mensaje={`Obteniendo ${isLoadingTurnos ? 'turnos' : 'voladuras'}`} />
      )}

      <Row>
        <h5>Total en el turno: {total} toneladas.</h5>
      </Row>

      <Row>
        <Col>
          <Table bordered>
            <thead>
              <tr className="primary-header">
                <th colSpan={8}>Voladura</th>
              </tr>
              <tr>
                <th>Zona Depositación</th>
                <th className="text-end">Tonelaje Acumulado</th>
                <th>Voladura</th>
                <th>Fase</th>
                <th className="text-end">Num</th>
                <th>Zona</th>
                <th className="text-end"># Camiones</th>
              </tr>
            </thead>
            <tbody>
              {hasVoladuras ? (
                voladuras.map(v => (
                  // eslint-disable-next-line react/jsx-key
                  <tr>
                    <td>{v.zonaDepositacion}</td>
                    <td className="text-end">{formatNumber(v.tonelajeAcumulado.toFixed(2))}</td>
                    <td>{v.voladura}</td>
                    <td>{v.fase}</td>
                    <td className="text-end">{v.num}</td>
                    <td>{v.zona}</td>
                    <td className="text-end">{v.camiones}</td>
                  </tr>
                ))
              ) : (
                <NoResults />
              )}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table bordered>
            <thead>
              <tr className="primary-header">
                <th colSpan={9}>Características Fisicas y Quimicas</th>
              </tr>
              <tr>
                <th className="text-end">%FeMag</th>
                <th className="text-end">FeTot</th>
                <th className="text-end">Sílice</th>
                <th className="text-end">Azufre</th>
                <th className="text-end">Calcio</th>
                <th className="text-end">Fósforo</th>
                <th className="text-end">MgO</th>
                <th className="text-end">AL<sub>2</sub>O<sub>3</sub></th>
                <th className="text-end">kwh/t Mol Aut</th>
              </tr>
            </thead>
            <tbody>
              {hasVoladuras ? (
                voladuras.map(c => (
                  // eslint-disable-next-line react/jsx-key
                  <tr>
                    <td className="text-end">{c.feM}</td>
                    <td className="text-end">{c.feT}</td>
                    <td className="text-end">{c.siO2}</td>
                    <td className="text-end">{c.s}</td>
                    <td className="text-end">{c.caO}</td>
                    <td className="text-end">{c.p}</td>
                    <td className="text-end">{c.mgO}</td>
                    <td className="text-end">{c.al203}</td>
                    <td className="text-end">{c.dureza}</td>
                  </tr>
                ))
              ) : (
                <NoResults colSpan={9} />
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <EditarPosicionador cerrarModal={setEstadoModal} estadoModal={estadoModal} />
    </Container>
  );
}
