import { useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Title from "../components/Title";
import useError from "../hooks/useError";

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { ICambiarContrasena } from "../models/interfaces/usuarios/ICambiarContrasena";
import { cambiarContrasenaService } from "../services/usuarios.service";
import { cambiarContrasenaSchema } from "../utils/validations/cambiar-contrasena.validation";



export default function CambiarContrasena() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { ErrorAlert, setError } = useError();
    const { formState: { errors }, handleSubmit, register } = useForm<ICambiarContrasena>({
      mode: 'onBlur',
      resolver: yupResolver(cambiarContrasenaSchema)
    });
  
    const {mutateAsync: mutateCambiarContrasenaAsync, isLoading } = useMutation(
        cambiarContrasenaService,
        {
            onSuccess: () => setIsOpen(true),
            onError: (error) => setError(error)
        })

    const onSubmit = async formData => await mutateCambiarContrasenaAsync(formData)
    
  
    return (
      <Container fluid>
        <Title titulo="Cambiar Contraseña" />
        <div className="row justify-content-center">
          <div className="col-xs-12 col-lg-6">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ErrorAlert />
              <Form.Group controlId="old-password" className="my-2">
                <Form.Label className="required-field">Antigua Contraseña</Form.Label>
                <Form.Control
                  isInvalid={!!errors.oldPassword}
                  placeholder="Antigua Contraseña"
                  type="password"
                  {...register('oldPassword')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.oldPassword?.message}
                </Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group controlId="new-password" className="my-2">
                <Form.Label className="required-field">Nueva Contraseña</Form.Label>
                <Form.Control
                  isInvalid={!!errors.newPassword}
                  placeholder="Nueva Contraseña"
                  type="password"
                  {...register('newPassword')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.newPassword?.message}
                </Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group controlId="repeat-password" className="my-2">
                <Form.Label className="required-field">Confirmar Nueva Contraseña</Form.Label>
                <Form.Control
                  isInvalid={!!errors.repeatPassword}
                  name="repeatPassword"
                  placeholder="Confirmar Nueva Contraseña"
                  type="password"
                  {...register('repeatPassword')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.repeatPassword?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="my-2" disabled={isLoading} type="submit" variant="primary">
                Cambiar Contraseña
              </Button>
            </Form>
          </div>
        </div>
        <Modal backdrop="static" show={isOpen}>
          <Modal.Header closeButton>
            <Modal.Title>Contraseña actualizada</Modal.Title>
          </Modal.Header>
          <Modal.Body>La contraseña fue actualizada con éxito</Modal.Body>
          <Modal.Footer>
            <Link to="/" className="btn btn-primary">
              Aceptar
            </Link>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }