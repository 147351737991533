import { createContext } from 'react';

import { IDataContext } from '../models/IDataContext';

const DataContext = createContext<IDataContext>({
  dataUser: {
    usuario: null,
    roles: [],
    area: '',
    areaId: 0,
  },
  setDataUser: () => undefined,
});

export default DataContext;
