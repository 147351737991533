import { useState } from 'react'
import { Alert } from 'react-bootstrap'

function useAlert(variant: string) {
  const [message, setAlertState] = useState<string>('')

  const onClose = () => setAlertState('')
  const CustomAlert = () =>
  message && (
      <Alert className="mt-4" onClose={onClose} dismissible variant={variant}>
        {message}
      </Alert>
    )
  const setAlert = (alertMessage: string) =>
  setAlertState(alertMessage)

  return { CustomAlert, setAlert }
}

export default useAlert