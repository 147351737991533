import { useQuery } from '@tanstack/react-query';

import { getViajesCamionesService } from '../services/viajesCamion.service';

export default function useViajes({ fecha, turnoId, options }) {
  const viajes = useQuery(
    ['viajes-camiones', fecha, turnoId],
    () => getViajesCamionesService(fecha, turnoId),
    { enabled: Boolean(fecha && turnoId), retry: 0, ...options },
  );

  return viajes;
}
