import { ICamiones, IRegistroCamion } from '../models/interfaces/catalogos/ICatalogos';
import http from '../utils/http';

export const addCamionService = async (dataRequest: IRegistroCamion) => {
  try {
    const response = await http.post('Camion/registrarCamion', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editCamionService = async (dataRequest: ICamiones) => {
  try {
    const response = await http.put(`Camion/EditarCamion/${dataRequest.id}`, dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getCamionService = async () => {
  try {
    const response = await http.get('Camion/ObtenerCamion');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getByIdCamionService = async (idCamion: number) => {
  try {
    const response = await http.get(`Camion/ObtenerCamion/${idCamion}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteCamionService = async (camionId: number) => {
  try {
    const response = await http.delete(`Camion/EliminarCamion/${camionId}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
