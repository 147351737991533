import axios from 'axios';

import { OpenAPI } from '../client';

const baseURL = process.env.REACT_APP_API;
const instancia = axios.create({
  baseURL,
});

instancia.interceptors.request.use(
  response => {
    const credenciales = localStorage.getItem('credenciales');
    if (credenciales) {
      const objetoCredenciales = JSON.parse(credenciales);
      response.headers.Authorization = `Bearer ${objetoCredenciales.token}`;
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

//i Interceptamos las respuestas
instancia.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && window.location.pathname !== '/') {
      localStorage.removeItem('credenciales');
      window.location.href = '/';
    }
    throw error;
  },
);

export function setAuthorizationHeaderInterceptors() {
  OpenAPI.interceptors.request.use(req => {
    const credenciales = localStorage.getItem('credenciales');
    if (credenciales) {
      const objetoCredenciales = JSON.parse(credenciales);
      req.headers.Authorization = `Bearer ${objetoCredenciales.token}`;
    }

    return req;
  });

  OpenAPI.interceptors.response.use(response => {
    if (response.status === 401 && window.location.pathname !== '/') {
      localStorage.removeItem('credenciales');
      window.location.href = '/';
      throw new Error(`${response.status} - ${response.data}`);
    }

    return response;
  });
}

export default instancia;
