import { useMutation, useQuery } from '@tanstack/react-query';

import {
  AgregarCalidadesRequest,
  AreaTrituracionService,
  AreaTrituracionViewModel,
  GetApiTrituracionSecundariaCalidadesByAreaData,
  GetApiTrituracionSecundariaCalidadesByAreaResponse,
  TrituracionSecundariaService,
} from '../client';
import { queryClient, QueryConfig } from '../config/queryClient';
import { TurnosEnum } from '../models/enums/turnos.enum';
import { IColeccionTrituracionSecundaria } from '../models/interfaces/ITrituracionSecundaria/IMovimientosTrituracionSecundaria';
import { getColeccionMovimientosTrituracion } from '../services/movimientoTrituracionSecundaria.service';
import { reporteAreaTrituracionSecundaria } from '../services/reportes.service';

type UseColeccionTrituracionSecundariaProps = {
  fecha: string;
  turnoId: TurnosEnum;
  areaTrituracion: number;
  config?: QueryConfig<Record<string, IColeccionTrituracionSecundaria[]>>;
};

export function useDataTrituracionSecundaria({
  fecha,
  turnoId,
  areaTrituracion,
  config = {},
}: UseColeccionTrituracionSecundariaProps) {
  return useQuery(
    ['data-trituracion-secundaria', fecha, turnoId, areaTrituracion],
    async () => {
      if (turnoId !== TurnosEnum.TodoElDia) {
        const nombreTurno = TurnosEnum[turnoId];
        const data = await getColeccionMovimientosTrituracion({ fecha, turnoId, areaTrituracion });

        return { [nombreTurno]: data };
      }

      //? Cuando el turno es "Todo el día", obtiene los datos de todos los demás turnos
      const todosLosTurnos = [
        getColeccionMovimientosTrituracion({ fecha, turnoId: TurnosEnum.Primero, areaTrituracion }),
        getColeccionMovimientosTrituracion({ fecha, turnoId: TurnosEnum.Segundo, areaTrituracion }),
        getColeccionMovimientosTrituracion({ fecha, turnoId: TurnosEnum.Tercero, areaTrituracion }),
      ];

      const data = (await Promise.all(todosLosTurnos)).flat();

      const result = data.reduce(
        (acc, current) => {
          const existingItem = acc[TurnosEnum[current.turnoId]];

          return { ...acc, [TurnosEnum[current.turnoId]]: [...existingItem, current] };
        },
        {
          [TurnosEnum[TurnosEnum.Primero]]: [],
          [TurnosEnum[TurnosEnum.Segundo]]: [],
          [TurnosEnum[TurnosEnum.Tercero]]: [],
        },
      );

      return result;
    },
    config,
  );
}

type UseReporteTrituracionSecundariaProps = {
  fecha: string;
  turnoId: TurnosEnum;
  areaTrituracion: number;
  config?: QueryConfig<any[]>;
};

export function useReporteTrituracionSecundaria({
  fecha,
  turnoId,
  areaTrituracion,
  config = {},
}: UseReporteTrituracionSecundariaProps) {
  return useQuery(
    ['reporte-trituracion-secundaria', fecha, turnoId, areaTrituracion],
    () => {
      if (turnoId !== TurnosEnum.TodoElDia) {
        return reporteAreaTrituracionSecundaria({ fecha, turnoId, areaTrituracion });
      }
    },
    config,
  );
}

export function useCalidadesTrituracionSecundariaQuery(
  args: GetApiTrituracionSecundariaCalidadesByAreaData,
  config: QueryConfig<GetApiTrituracionSecundariaCalidadesByAreaResponse> = {},
) {
  return useQuery(
    ['trituracion-secundaria', 'calidades', args.area.toLowerCase()],
    () => TrituracionSecundariaService.getCalidadesByArea(args),
    config,
  );
}

export function useAgregarCalidadesTrituracionSecundariaMutation() {
  return useMutation({
    mutationFn: (data: AgregarCalidadesRequest) =>
      TrituracionSecundariaService.postCalidades({ requestBody: data }),
    onSuccess(response) {
      queryClient.invalidateQueries([
        'trituracion-secundaria',
        'calidades',
        response.areaTrituracion?.descripcion?.toLowerCase(),
      ]);
    },
  });
}

export function useAreaDeTrituracionQuery(
  area: string,
  config: QueryConfig<AreaTrituracionViewModel> = {},
) {
  return useQuery(
    ['trituracion-secundaria', 'areas', area],
    () => AreaTrituracionService.getByArea({ area }),
    config,
  );
}
