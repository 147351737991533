import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';

import Loading from '../../components/Loading';
import EditarAlimentadorDepositoModal from '../../components/modals/EditarAlimentadorDeposito';
import EliminarModal from '../../components/modals/EliminarModal';
import useError from '../../hooks/useError';
import { IAlimentadorDeposito } from '../../models/interfaces/catalogos/ICatalogos';
import {
  deleteAlimentadorDepositoService,
  getAlimentadorDepositoService,
} from '../../services/alimentadorDeposito.service';

export default function AlimentadorDeposito() {
  const [editModal, setEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<number>(0);
  const [alimentadorDeposito, setAlimentadorDeposito] = useState<IAlimentadorDeposito[]>([]);
  const [itemBusqueda, setItemBusqueda] = useState<string>('');
  const [detalle, setDetalle] = useState<IAlimentadorDeposito>({
    id: 0,
    alimentadorId: 0,
    alimentador: '',
    primerDepositoMineralId: 0,
    segundoDepositoMineralId: 0,
    depositoUno: '',
    depositoDos: '',
    porcentajeUno: 0,
    porcentajeDos: 0,
  });
  const { ErrorAlert, setError } = useError();
  const { data: almdepositos, isLoading } = useQuery(
    ['alimentador-deposito'],
    getAlimentadorDepositoService,
    {
      onError: e => {
        setError(e);
      },
    },
  );

  const openModal = () => {
    setDetalle({
      id: 0,
      alimentadorId: 0,
      alimentador: '',
      primerDepositoMineralId: 0,
      segundoDepositoMineralId: 0,
      depositoUno: '',
      depositoDos: '',
      porcentajeUno: 0,
      porcentajeDos: 0,
    });
    setEditModal(true);
  };

  const openEditModal = (values: IAlimentadorDeposito) => {
    setDetalle(values);
    setEditModal(true);
  };

  const openDeleteModal = (values: IAlimentadorDeposito) => {
    setDeleteModal(values.id);
  };

  const buscarDepositoMineral = (useParam: string) => {
    setItemBusqueda(useParam);
    setAlimentadorDeposito(almdepositos);
    setAlimentadorDeposito(alimentadorDeposito);
  };

  return (
    <Container>
      <ErrorAlert />
      {isLoading && <Loading mensaje="Obteniendo depositos" />}
      <Row>
        <Col className="my-3 d-flex align-items-end justify-content-between" sm={6} xs={7}>
          <InputGroup>
            <Form.Control
              name="buscar"
              onChange={({ target }) => buscarDepositoMineral(target.value)}
              placeholder="Alimentador Deposito"
              value={itemBusqueda}
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-3 d-flex align-items-end justify-content-end" sm={6} xs={5}>
          <Button className="text-white" onClick={() => openModal()} variant="secondary">
            Agregar Nueva Relacion AL/DL
          </Button>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12}>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th>Alimentador</th>
                <th>Deposito</th>
                <th>Porcentaje Alimentación</th>
                <th>Deposito Dos</th>
                <th>Porcentaje Alimentación Dos</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {almdepositos?.length ? (
                almdepositos
                  .filter(({ alimentador, depositoUno, depositoDos }: IAlimentadorDeposito) => {
                    if (!itemBusqueda) return true;

                    const regex = new RegExp(itemBusqueda, 'gi');
                    return (
                      alimentador.match(regex) ||
                      depositoUno.match(regex) ||
                      depositoDos.match(regex)
                    );
                  })
                  .map((alim: IAlimentadorDeposito) => (
                    <tr key={alim.alimentadorId}>
                      <td>{alim.alimentador}</td>
                      <td>{alim.depositoUno}</td>
                      <td>{alim.porcentajeUno}</td>
                      <td>{alim.depositoDos}</td>
                      <td>{alim.porcentajeDos}</td>
                      <td>
                        <IconButton
                          onClick={() => {
                            openEditModal(alim);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => openDeleteModal(alim)}>
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={4}>No hay Alimentadores Deposito registrados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <EditarAlimentadorDepositoModal
        detalle={detalle}
        estadoModalCatalogo={editModal}
        onClose={() => setEditModal(false)}
      />
      <EliminarModal
        confirmationMessage="¿Estas seguro de Eliminar la relacion entre Alimentador y Deposito?"
        handleClose={() => setDeleteModal(0)}
        id={deleteModal}
        mutationFn={() => deleteAlimentadorDepositoService(deleteModal)}
        queryKey="alimentador-deposito"
        title="Eliminar Alimentador Deposito"
      />
    </Container>
  );
}
