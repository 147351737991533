import { IEditarPosicionador } from '../models/interfaces/voladuras/IEditarPosicionador';
import http from '../utils/http';

export const editPosicionApilador = async (requestData: IEditarPosicionador) => {
  try {
    const response = await http.put('rockwell/EditarRockwellPosicion', requestData);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
