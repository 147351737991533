import { TurnosEnum } from '../models/enums/turnos.enum';
import { IViajesCamionesTrayectos } from '../models/interfaces/movimientoMineral/IMovimientoMineral';
import { IViajes, IViajesMovimientoMineral } from '../models/interfaces/viajes/IViajes';
import http from '../utils/http';

export const addViajesCamionesService = async (dataRequest: IViajesCamionesTrayectos) => {
  try {
    await http.post('ViajesCamionTrayecto/RegistrarViajes', dataRequest);
  } catch (ex) {
    throw ex;
  }
};

export const getViajesCamionesService = async (fecha: string, turnoId: number) => {
  try {
    const { data } = await http.get<IViajes[]>(
      `ViajesCamionTrayecto/ObtenerViajes/${fecha}/${turnoId}`,
    );
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getViajesByDateService = async (fecha: string, turnoId: number) => {
  try {
    const { data } = await http.get<IViajesMovimientoMineral>(
      `ViajesCamionTrayecto/GetViajesByDate/${fecha}`,
      {
        params: {
          turnoId: turnoId !== TurnosEnum.TodoElDia ? turnoId : null,
        },
      },
    );
    return data;
  } catch (ex) {
    throw ex;
  }
};
