import { ITurnos, IRegistroTurno } from '../models/interfaces/catalogos/ICatalogos';
import http from '../utils/http';

export const addTurnoService = async (dataRequest: IRegistroTurno) => {
  try {
    const response = await http.post('Turno/RegistrarTurno', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editTurnoService = async (dataRequest: ITurnos) => {
  try {
    const response = await http.put(`Turno/EditarTurno/${dataRequest.id}`, dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getTurnoService = async () => {
  try {
    const response = await http.get('Turno/ObtenerTurno');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getByIdTurnoService = async (idTurno: number) => {
  try {
    const response = await http.get(`Turno/ObtenerTurno/${idTurno}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteTurnoService = async (TurnoId: number) => {
  try {
    const response = await http.delete(`Turno/EliminarTurno/${TurnoId}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
