import { GetMovTrituracionByFechaRequest } from '../models/interfaces/ITrituracionSecundaria/api-requests';
import http from '../utils/http';

export const reporteAreaTrituracionSecundaria = async (params: GetMovTrituracionByFechaRequest) => {
  try {
    const { data } = await http.get<any>('ExportExcel/ExportExcelMovimientosTrituracion', {
      params,
      responseType: 'blob',
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};
