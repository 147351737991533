import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Button } from 'react-bootstrap';

import { EliminarModalTypes } from '../../models/types/modal.type';

export default function EliminarModal({
  confirmationMessage,
  handleClose,
  id,
  mutationFn,
  queryKey,
  title,
}: EliminarModalTypes) {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
      handleClose();
    },
  });

  const onDelete = () => {
    mutate(id);
  };

  return (
    <Modal backdrop={isLoading ? 'static' : true} show={!!id} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{confirmationMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-light border-dark" onClick={handleClose}>
          Cerrar
        </Button>
        <Button className="btn-danger" onClick={onDelete}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
