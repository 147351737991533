import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';

import FechaTurno from '../../components/FechaTurno';
import Loading from '../../components/Loading';
import useCamiones from '../../hooks/useCamiones';
import useDepositoMineral from '../../hooks/useDepositoMineral';
import useFechaTurno from '../../hooks/useFechaTurno';
import useTurnos from '../../hooks/useTurnos';
import { TurnosEnum } from '../../models/enums/turnos.enum';
import { IMovimientoTrituracion } from '../../models/interfaces/ITrituracionSecundaria/IMovimientosTrituracionSecundaria';
import {
  MovimientoTrituracionSecundariaService,
  addMovimientoTrituracionSecundariaService,
} from '../../services/movimientoTrituracionSecundaria.service';
import style from './RegistroTrituracionSecundaria.module.scss';

const mensajesTonelajes = Array.from({ length: 8 }, (_, index) => ({
  [`acumulado${index}`]: '',
}));
const turnosConReferencia = [TurnosEnum.Segundo, TurnosEnum.Tercero];

export default function RegistroTrituracionSecundaria(props: { setError: Function }) {
  const { setError } = props;
  const { Primero, Segundo } = TurnosEnum;
  const [horaInicioDelTurno, setHoraInicioDelTurno] = useState('6');
  //? La posición 1 del array corresponde al último tonelaje del turno anterior.
  //? La posición 0 corresponde al penúltimo tonelaje, usado para referencia visual.
  const [ultimosTonelajesTurnoAnterior, setUltimosTonelajesTurnoAnterior] = useState([0, 0]);
  const [puedeAgregarAcumulado, setPuedeAgregarAcumulado] = useState(true);
  const [renderInputAcumulado, setRenderInputAcumulado] = useState(false);
  const [mensajeAcumulado, setMensajeAcumulado] = useState<any[]>(mensajesTonelajes);
  const [movimientoTrituracion, setMovimientoTrituracion] = useState<IMovimientoTrituracion>({
    camionId: 0,
    depositoMineralId: 0,
    toneladas: 0,
    viajes: 0,
    tonelajeEstimado: 0,
  });
  const [movimientosTrituracion, setMovimientosTrituracion] = useState<IMovimientoTrituracion[]>(
    [],
  );
  const camionRef = useRef(null);
  const { data: turnos } = useTurnos();
  const { fecha, turno } = useFechaTurno();
  const queryClient = useQueryClient();
  const { data: camiones } = useCamiones({
    onError: e => setError(e),
  });
  const { data: depositos } = useDepositoMineral({
    onError: e => setError(e),
  });

  const turnoActualTieneReferenciaVisual = turnosConReferencia.includes(turno);

  const {
    mutate: getColeccionViajes,
    isLoading: isLoadingTrituracionSecundaria,
    isError: isErrorGetTrituracion,
  } = useMutation({
    mutationFn: MovimientoTrituracionSecundariaService.getByFechaTurno,
    onSuccess: response => {
      if (response) {
        remove();

        const formatResponse = Object.entries(response).map(item => ({ [item[0]]: item[1] }));
        const tonelajes = formatResponse.filter(vl =>
          Object.keys(vl).some(key => key.includes('tonelajeAcumulado')),
        );

        const objectTonelajes = tonelajes.map(tonelaje => {
          const keyTon = `${Object.keys(tonelaje)[0]}`;
          return { [keyTon]: tonelaje[keyTon] };
        });

        const segmentoTonelajes =
          turno === Primero
            ? objectTonelajes.slice(6, 14)
            : turno === Segundo
            ? objectTonelajes.slice(14, 22)
            : [...objectTonelajes.slice(22), ...objectTonelajes.slice(0, 6)];
        const segmentoTonelajesValuesByTurno = segmentoTonelajes.map(
          item => item[Object.keys(item)[0]],
        );

        segmentoTonelajesValuesByTurno.map((tonItem: number) => append({ value: tonItem }));
        setMovimientosTrituracion(response.viajesTrituracionSecundaria);
        setRenderInputAcumulado(false);
      }
    },
    onError: () => {
      remove();
      append(Array.from({ length: 24 / turnos?.length || 8 }).map(() => ({ value: 0 })));
      setMovimientosTrituracion([]);
      setRenderInputAcumulado(true);
    },
  });
  const { mutate: getViajesAnteriores } = useMutation({
    mutationFn: MovimientoTrituracionSecundariaService.getByFechaTurno,
    onSuccess: response => {
      if (response.turnoId === Primero) {
        setUltimosTonelajesTurnoAnterior([
          response.tonelajeAcumulado13,
          response.tonelajeAcumulado14,
        ]);
      } else if (response.turnoId === Segundo) {
        setUltimosTonelajesTurnoAnterior([
          response.tonelajeAcumulado21,
          response.tonelajeAcumulado22,
        ]);
      }
    },
    onError: (error: AxiosError) => {
      if (error?.response?.status === 404) {
        setPuedeAgregarAcumulado(false);
        setError('');
      } else {
        setError(error);
      }
      setUltimosTonelajesTurnoAnterior([0, 0]);
    },
  });
  const { mutate, isLoading: isLoadingAddTrituracionSecundaria } = useMutation(
    addMovimientoTrituracionSecundariaService,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['movimientos-trituracion-secundaria', fecha, turno]);
        getColeccionesViajes();
      },
    },
  );
  const { control, handleSubmit, register, setValue, getValues, watch } = useForm();
  const {
    fields: tonelajeAcumuladoFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'tonelajeAcumulado',
  });
  const acumuladosValidos = mensajeAcumulado
    .map((it, index) => it[`acumulado${index}`])
    .every(vl => vl === '');

  //? Este watch debe ejecutarse, ya que se necesita para que cada modificación a los valores cause un re-render
  //? Y se calculen correctamente los valores al instante.
  watch('tonelajeAcumulado');

  const { tableHeader, turnosTonelajeAcumulado } = useMemo(() => {
    const array = Array.from({ length: 24 / turnos?.length || 8 });
    if (turno && turnos) {
      const currentTurno = turnos?.find(({ id }) => id === +turno);
      const startingHour = +currentTurno.inicio.substr(0, 2);

      const turnosTonelajeAcumulado = array
        .map((_, i) => (i + startingHour) % 24)
        .map(val => val + 1);

      return {
        tableHeader: currentTurno.descripcion,
        turnosTonelajeAcumulado,
      };
    }

    return { tableHeader: '', turnosTonelajeAcumulado: array as number[] };
  }, [turno, turnos]);

  const onSubmit = form => {
    const totalTurnos = 24;
    let tonelajesAcumulados = {};

    //i Valores tonelaje acumulado, key del turno
    const tonAcumKeyTurno = form.tonelajeAcumulado.map(({ value }, index) => ({
      [`turno${turnosTonelajeAcumulado[index] || 24}`]: value,
    }));

    //i Recorrido de los 24 turnos y creación de objeto "tonelajesAcumulados"
    for (let indiceTurno = 0; indiceTurno < totalTurnos; indiceTurno++) {
      tonelajesAcumulados = {
        ...tonelajesAcumulados,
        [`TonelajeAcumulado${indiceTurno + 1}`]: tonAcumKeyTurno.find(
          t => t[`turno${indiceTurno + 1}`],
        )
          ? tonAcumKeyTurno.find(t => t[`turno${indiceTurno + 1}`])[`turno${indiceTurno + 1}`]
          : 0,
      };
    }

    const f = {
      fecha,
      turnoId: turno,
      viajesTrituracionSecundaria: movimientosTrituracion,
      ...tonelajesAcumulados,
    };
    mutate(f);
  };

  const addMovimientoTrituracion = () => {
    if (
      movimientoTrituracion.camionId &&
      movimientoTrituracion.depositoMineralId &&
      movimientoTrituracion.toneladas &&
      movimientoTrituracion.viajes &&
      movimientoTrituracion.tonelajeEstimado
    ) {
      setMovimientosTrituracion(movs => {
        setValue('viajesTrituracionSecundaria', [...movs, movimientoTrituracion]);
        return [...movs, movimientoTrituracion];
      });
      setMovimientoTrituracion({
        camionId: 0,
        depositoMineralId: 0,
        toneladas: 0,
        viajes: 0,
        tonelajeEstimado: 0,
      });
      camionRef.current.focus();
    }
  };

  const deleteMovimientoTrituracion = (indice: number) => {
    setMovimientosTrituracion(movs => {
      const restantes = movs.filter((_, index) => {
        return index !== indice;
      });
      setValue('viajesTrituracionSecundaria', restantes);
      return restantes;
    });
  };

  const calcularTonelajeEstimado = () => {
    setMovimientoTrituracion((mv: any) => ({
      ...mv,
      tonelajeEstimado:
        mv.camionId && mv.viajes
          ? (+camiones.find(cm => cm.id === mv.camionId)?.volumen || 0) * mv.viajes
          : 0,
    }));
  };

  const renderTph = (indiceAcumulado: number) => {
    const [primeraHoraTurno] = turnosTonelajeAcumulado;

    //i Si la primer hora del turno son las 7:00 A.M. se pone el acumulado del ultimo
    //i del turno anterior con el acumulado de la primera hora del turno actual
    if (indiceAcumulado === 0 && primeraHoraTurno !== 7) {
      const primerHoraTurnoActual = getValues(`tonelajeAcumulado.${indiceAcumulado}.value`);
      const result =
        primeraHoraTurno !== 15 && primeraHoraTurno !== 23
          ? 0
          : primerHoraTurnoActual - ultimosTonelajesTurnoAnterior[1];
      return result || 0;
    } else {
      const valorAcumulado = getValues(`tonelajeAcumulado.${indiceAcumulado}.value`) || 0;
      const valorAcumuladoAnterior =
        getValues(`tonelajeAcumulado.${indiceAcumulado - 1}.value`) || 0;
      return valorAcumulado ? valorAcumulado - valorAcumuladoAnterior : 0;
    }
  };

  const getColeccionesViajes = () => {
    setPuedeAgregarAcumulado(true);
    setMensajeAcumulado(mensajesTonelajes);
    getColeccionViajes({ fecha, turnoId: turno });
    if (turno > 1 && turno < 4) getViajesAnteriores({ fecha, turnoId: turno - 1 });
  };

  const validacionAcumulado = (index: number, tonelajeActual: number) => {
    let mensaje = '';
    const campoAnterior = getValues(`tonelajeAcumulado.${index - 1}.value`);
    const campoSiguiente = getValues(`tonelajeAcumulado.${index + 1}.value`);
    const campoSiguienteNumerico = isNaN(campoSiguiente) ? 0 : campoSiguiente;

    //i El primer campo sólo válida que el campo siguiente sea mayor que el valor actual
    if (!index) {
      if (!!campoSiguienteNumerico) {
        mensaje =
          tonelajeActual > campoSiguienteNumerico ? 'El campo siguiente no puede ser menor' : '';
      }

      //i Si el turno es segundo o tercero, obtenemos el último tonelaje del turno anterior
      if (turno > 1 && !!ultimosTonelajesTurnoAnterior) {
        mensaje =
          ultimosTonelajesTurnoAnterior[1] > tonelajeActual
            ? 'El campo anterior no puede ser mayor'
            : '';
      }

      //i Se revisan validaciones de campos anteriroes y siguientes
      const mensajeCampoAnterior = mensajeAcumulado[index + 1][`acumulado${index + 1}`];
      const mensajeAnterior = !!mensajeCampoAnterior && mensajeCampoAnterior.includes('anterior');

      // i Limpiamos el campo siguiente si se cumple la condición
      if (mensajeAnterior) {
        if (campoSiguiente > tonelajeActual) {
          setMensajeAcumulado(acum =>
            acum.map((item, indice) => {
              if (indice === index + 1) {
                return { ...item, [`acumulado${indice}`]: '' };
              }
              return item;
            }),
          );
        }
      }
    } else if (index === Segundo) {
      //i El último campo sólo válida el campo anterior que no sea mayor al mismo
      mensaje = campoAnterior > tonelajeActual ? 'El campo anterior no puede ser mayor' : '';
      //i Se revisan validaciones de campos anteriroes y siguientes
      const mensajeCampoAnterior = mensajeAcumulado[index - 1][`acumulado${index - 1}`];
      const mensajeSiguiente = !!mensajeCampoAnterior && mensajeCampoAnterior.includes('siguiente');

      if (mensajeSiguiente) {
        //i Limipiamos el campo anterior si se cumple la condición
        if (tonelajeActual > campoAnterior) {
          setMensajeAcumulado(acum =>
            acum.map((item, indice) => {
              if (indice === index - 1) {
                return { ...item, [`acumulado${indice}`]: '' };
              }
              return item;
            }),
          );
        }
      }
    } else {
      //i El resto de los campos válidan que el anterior sea menor y el siguiente sea mayor
      mensaje =
        tonelajeActual > campoSiguienteNumerico && !!campoSiguienteNumerico
          ? 'El campo siguiente no puede ser menor'
          : campoAnterior > tonelajeActual
          ? 'El campo anterior no puede ser mayor'
          : '';

      //i Se revisan validaciones de campos anteriroes y siguientes
      const mensajeCampoAnterior = mensajeAcumulado[index - 1][`acumulado${index - 1}`];
      const mensajeSiguiente = !!mensajeCampoAnterior && mensajeCampoAnterior.includes('siguiente');

      if (mensajeSiguiente) {
        //i Limipiamos el campo anterior si se cumple la condición
        if (tonelajeActual > campoAnterior) {
          setMensajeAcumulado(acum =>
            acum.map((item, indice) => {
              if (indice === index - 1) {
                return { ...item, [`acumulado${indice}`]: '' };
              }
              return item;
            }),
          );
        }
      } else {
        // i Limpiamos el campo siguiente si se cumple la condición
        if (campoAnterior > tonelajeActual) {
          setMensajeAcumulado(acum =>
            acum.map((item, indice) => {
              if (indice === index + 1) {
                return { ...item, [`acumulado${indice}`]: '' };
              }
              return item;
            }),
          );
        }
      }
    }

    setMensajeAcumulado(acum =>
      acum.map((it, indice) => {
        if (indice === index) {
          return { ...it, [`acumulado${index}`]: mensaje };
        }
        return it;
      }),
    );
  };

  const tonsAcumuladasPorTurno = tonelajeAcumuladoFields
    .map((tonAcum, index) => ({
      id: tonAcum.id,
      value: renderTph(index),
    }))
    .reduce((total, current) => total + current.value, 0);
  const tonsAlimentadasPorTurno = movimientosTrituracion?.reduce(
    (total, current) => total + Number(current.toneladas),
    0,
  );

  useEffect(() => {
    console.log(
      '🚀 ~ RegistroTrituracionSecundaria ~ tonsAlimentadasPorTurno:',
      tonsAlimentadasPorTurno,
    );
    if (fecha && turno) {
      getColeccionesViajes();
      const currentTurno = turnos?.find(({ id }) => id === +turno);
      const startingHour = currentTurno?.inicio.substring(0, 2);

      setHoraInicioDelTurno(startingHour);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fecha, turno, turnos]);

  useEffect(() => {
    return () => {
      setMensajeAcumulado(mensajesTonelajes);
    };
  }, []);

  return (
    <>
      {isLoadingAddTrituracionSecundaria && (
        <Loading mensaje="Registrando Trituración Secundaria" />
      )}
      {isLoadingTrituracionSecundaria && <Loading mensaje="Obteniendo Tonelajes Acumulados" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-4">
          <Col className="d-flex align-items-center" xs={10}>
            <FechaTurno className="col" />

            <span className="mx-4">
              {tonsAcumuladasPorTurno.toFixed(2)} tons acumuladas /{' '}
              {tonsAlimentadasPorTurno.toFixed(2)} tons alimentadas
            </span>
            <span>
              Diferencia:{' '}
              <strong>
                {Math.abs(tonsAcumuladasPorTurno - tonsAlimentadasPorTurno).toFixed(2)} tons
              </strong>
            </span>
          </Col>
          <Col className="d-flex align-items-center justify-content-end" xs={2}>
            <Button
              className="text-white"
              disabled={!renderInputAcumulado || !puedeAgregarAcumulado || !acumuladosValidos}
              type="submit"
              variant="secondary"
            >
              Guardar Registro
            </Button>
          </Col>
        </Row>
        <Row>
          <Table bordered>
            <tr className={style.tableHeader}>
              <th>Turno</th>
              <th colSpan={9}>{tableHeader}</th>
            </tr>
            {turnosTonelajeAcumulado && (
              <>
                <tr className={clsx(style.tableRow, 'text-center fw-bold')}>
                  <th className={style.tableHeader}>Hora</th>
                  {turnoActualTieneReferenciaVisual && (
                    <td className={style.referenciaVisual}>{horaInicioDelTurno}</td>
                  )}

                  {turnosTonelajeAcumulado.map(t => (
                    <td key={t}>{t}</td>
                  ))}
                </tr>
                <tr className={clsx(style.tableRow, 'text-center')}>
                  <td className={style.tableHeader}>Acumulado</td>
                  {turnoActualTieneReferenciaVisual && (
                    <td className={style.referenciaVisual}>
                      {ultimosTonelajesTurnoAnterior[1].toFixed(2)}
                    </td>
                  )}

                  {tonelajeAcumuladoFields.map((field, index: number) => (
                    <td key={field.id}>
                      {renderInputAcumulado ? (
                        <Form.Group>
                          <Form.Control
                            isInvalid={!!mensajeAcumulado[index][`acumulado${index}`]}
                            placeholder="Acumulado"
                            step="0.01"
                            type="number"
                            {...register(`tonelajeAcumulado.${index}.value`, {
                              valueAsNumber: true,
                              onChange: event => {
                                validacionAcumulado(index, +event?.target?.value || 0);
                                return event?.target?.value;
                              },
                            })}
                            disabled={!puedeAgregarAcumulado}
                          />
                          <Form.Control.Feedback className="d-block" type="invalid">
                            {mensajeAcumulado[index][`acumulado${index}`]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : (
                        getValues(`tonelajeAcumulado.${index}.value`)
                      )}
                    </td>
                  ))}
                </tr>
              </>
            )}
            <tr className={clsx(style.tableRow, 'text-center')}>
              <td className={style.tableHeader}>TPH</td>
              <>
                {turnoActualTieneReferenciaVisual && (
                  <td className={style.referenciaVisual}>
                    {(ultimosTonelajesTurnoAnterior[1] - ultimosTonelajesTurnoAnterior[0]).toFixed(
                      2,
                    )}
                  </td>
                )}

                {tonelajeAcumuladoFields.map((tonAcum, index) => (
                  <td key={tonAcum.id}>{renderTph(index).toFixed(2)}</td>
                ))}
              </>
            </tr>
            <tr className={clsx(style.tableRow, 'text-center')}>
              <td className={style.tableHeader}>Tons</td>
              <td colSpan={9}>{`${tonsAcumuladasPorTurno.toFixed(2)} TONS por turno`}</td>
            </tr>
          </Table>
        </Row>
        {/** Formulario de movimiento trituración */}
        <Row>
          <Col as={Form.Group} className="mb-4" controlId="camion" xl={2} xs={6}>
            <Form.Label>Camión</Form.Label>
            <Form.Select
              ref={camionRef}
              name="camionId"
              onChange={event => {
                setMovimientoTrituracion((mv: any) => ({ ...mv, camionId: +event.target.value }));
                calcularTonelajeEstimado();
              }}
              value={movimientoTrituracion.camionId}
            >
              <option value={0}>Selecciona Camión</option>
              {camiones
                ?.filter(cm => ['GDI', 'EMD'].includes(cm.areaDescripcion))
                .sort((a, b) => a.numCamion.localeCompare(b.numCamion))
                ?.map(({ id, numCamion }) => (
                  <option key={id} value={id}>
                    {numCamion}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col as={Form.Group} className="mb-4" controlId="deposito-mineral" xl={3} xs={6}>
            <Form.Label>Depósito Mineral</Form.Label>
            <Form.Select
              name="depositoMineralId"
              onChange={event =>
                setMovimientoTrituracion((mv: any) => ({
                  ...mv,
                  depositoMineralId: +event.target.value,
                }))
              }
              value={movimientoTrituracion.depositoMineralId}
            >
              <option value={0}>Selecciona Depósito Mineral</option>
              {depositos
                ?.sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                .map(({ id, descripcion }) => (
                  <option key={id} value={id}>
                    {descripcion}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col as={Form.Group} className="mb-4" controlId="toneladas" md={4} xl={2} xs={6}>
            <Form.Label>Toneladas</Form.Label>
            <Form.Control
              name="toneladas"
              onChange={event =>
                setMovimientoTrituracion((mv: any) => ({ ...mv, toneladas: event.target.value }))
              }
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.preventDefault();
                  addMovimientoTrituracion();
                }
              }}
              step="0.01"
              type="number"
              value={movimientoTrituracion.toneladas}
            />
          </Col>
          <Col as={Form.Group} className="mb-4" controlId="viajes" md={4} xl={2} xs={6}>
            <Form.Label>Viajes</Form.Label>
            <Form.Control
              name="viajes"
              onChange={event => {
                setMovimientoTrituracion((mv: any) => ({ ...mv, viajes: +event.target.value }));
                calcularTonelajeEstimado();
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.preventDefault();
                  addMovimientoTrituracion();
                }
              }}
              value={movimientoTrituracion.viajes}
            />
          </Col>
          <Col as={Form.Group} className="mb-4" controlId="tonelaje-estimado" md={3} xl={2} xs={8}>
            <Form.Label>Tonelaje Estimado</Form.Label>
            <Form.Control
              name="tonelajeEstimado"
              step="0.01"
              type="number"
              value={movimientoTrituracion.tonelajeEstimado}
              disabled
            />
          </Col>
          <Col className="p-4" md={1} xs={4}>
            <IconButton
              disabled={!renderInputAcumulado || !puedeAgregarAcumulado || !acumuladosValidos}
              onClick={() => addMovimientoTrituracion()}
            >
              <AddCircleIcon />
            </IconButton>
          </Col>
        </Row>

        <Row>
          <Col>
            <Table bordered>
              <thead>
                <tr className="primary-header">
                  <th>Camión</th>
                  <th>Origen De Alimentación</th>
                  <th>Toneladas</th>
                  <th>Viajes</th>
                  <th>Tonelaje Estimado</th>
                  {isErrorGetTrituracion && <th>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {movimientosTrituracion?.length ? (
                  movimientosTrituracion.map(
                    (
                      { camionId, depositoMineralId, toneladas, tonelajeEstimado, viajes },
                      index,
                    ) => (
                      <tr key={index}>
                        <td>{camiones.find(cm => cm.id === camionId)?.numCamion || ''}</td>
                        <td>
                          {depositos.find(dp => dp.id === depositoMineralId)?.descripcion || ''}
                        </td>
                        <td>{toneladas}</td>
                        <td>{viajes}</td>
                        <td>{tonelajeEstimado}</td>
                        {isErrorGetTrituracion && (
                          <td>
                            <IconButton onClick={() => deleteMovimientoTrituracion(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </td>
                        )}
                      </tr>
                    ),
                  )
                ) : (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      No hay movimientos de trituración registrados.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </form>
    </>
  );
}
