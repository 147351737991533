import clsx from 'clsx';
import { format, parse } from 'date-fns';
import { useMemo } from 'react';
import { Button, Col, Container, Form, Nav, Row, Table } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';

import FechaTurno from '../components/FechaTurno';
import useDepositoMineral from '../hooks/useDepositoMineral';
import useFechaTurno from '../hooks/useFechaTurno';
import useTurnos from '../hooks/useTurnos';
import '../styles/Pladial.scss';

const pladial = {
  turno1: [
    {
      hora: '07:00',
      'AL-1': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'AL-5': [
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-3': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
      ],
      'DL-4': [
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },

        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
    },
    {
      hora: '08:00',
      'AL-1': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'AL-5': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-3': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-4': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
    },
    {
      hora: '09:00',
      'AL-1': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'AL-5': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-3': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-4': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
    },
  ],
  turno2: [
    {
      hora: '07:00',
      'AL-1': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'AL-5': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-3': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-4': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
    },
    {
      hora: '08:00',
      'AL-1': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'AL-5': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-3': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-4': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
    },
    {
      hora: '09:00',
      'AL-1': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'AL-5': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-3': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
      'DL-4': [
        {
          id: 1,
          voladura: 'Patio 07',
          ktons: '5',
          ci: '0.85',
          femag: '19.6',
          fetot: '66.5',
          azufre: '0.120',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 12,
          voladura: 'F4A523A',
          ktons: '20',
          ci: '0.3',
          femag: '18.72',
          fetot: '64.74',
          azufre: '0.2',
          calcio: '1.31',
          silice: '4.32',
          fosforo: '0.02',
          mgo: '0.59',
          al2o3: '1.29',
          kwht: '10.61',
        },
        {
          id: 13,
          voladura: 'Patio08',
          ktons: '30',
          ci: '0.85',
          femag: '19.62',
          fetot: '66.53',
          azufre: '0.12',
          calcio: '1.11',
          silice: '2.88',
          fosforo: '0.043',
          mgo: '0.32',
          al2o3: '0.93',
          kwht: '8.2',
        },
        {
          id: 14,
          voladura: 'Patio 12',
          ktons: '20',
          ci: '0.85',
          femag: '18.63',
          fetot: '65.77',
          azufre: '0.09',
          calcio: '1.12',
          silice: '5.25',
          fosforo: '0.013',
          mgo: '0.47',
          al2o3: '1.23',
          kwht: '7.49',
        },
        {
          id: 15,
          voladura: 'F5A143A',
          ktons: '15',
          ci: '0.0',
          femag: '19.38',
          fetot: '68.04',
          azufre: '0.08',
          calcio: '0.65',
          silice: '2.39',
          fosforo: '0.01',
          mgo: '0.35',
          al2o3: '0.89',
          kwht: '10.09',
        },
      ],
    },
  ],
};

export default function Pladial() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: turnos } = useTurnos();
  const { turno } = useFechaTurno();
  const alimentador = searchParams.get('alimentador');
  const hora = searchParams.get('hora');
  const tipo = searchParams.get('tipo');
  const isManual = tipo === 'manual';
  const { data: depositos } = useDepositoMineral({ retry: 0, cacheTime: 0 });

  const setParams = (key, value) => {
    setSearchParams({ ...Object.fromEntries(searchParams), [key]: value });
  };

  const onHoraClick = hora => {
    setParams('hora', hora);
  };

  const getLink = (key, value) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, value);
    return '?' + params.toString();
  };

  const formatoHoras = (horaTurno: number, horaInicial: boolean = false) => {
    return horaTurno > -1
      ? format(parse(`${horaTurno || 24}:${horaInicial ? '01' : '00'}`, 'k:mm', new Date()), 'k:mm')
      : 0;
  };

  const { horasTurno } = useMemo(() => {
    const array = Array.from({ length: 24 / turnos?.length || 8 });
    if (turno && turnos) {
      const currentTurno = turnos.find(({ id }) => id === +turno);
      const startingHour = +currentTurno.inicio.substr(0, 2);

      const horasTurno = array.map((_, i) => (i + startingHour) % 24).map(val => val);

      return {
        horasTurno,
      };
    }
    return { horasTurno: array };
  }, [turno, turnos]);

  return (
    <Container className="d-flex flex-column pladial" fluid>
      <FechaTurno />
      <Row>
        <Col className="d-flex flex-column pladial__box" lg={4} md={5} sm={6} xl={3} xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-3">
              <strong>HORA</strong>
            </h4>
            <Button size="sm" variant="dark">
              Turno Completo
            </Button>
          </div>
          {horasTurno.map((horaTurno: number) => (
            <Button
              key={horaTurno}
              className="mb-2"
              onClick={() => onHoraClick(horaTurno)}
              variant={+hora === horaTurno ? 'primary' : 'secondary'}
            >
              <>
                {formatoHoras(horaTurno, true)}
                {' - '}
                {formatoHoras(horaTurno + 1)}
              </>
            </Button>
          ))}
        </Col>
        <Col lg={8} md={7} sm={6} xl={9} xs={12}>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                as={Link}
                className={clsx(alimentador === 'AL-1' && 'active')}
                to={getLink('alimentador', 'AL-1')}
              >
                AL-1
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                className={clsx(alimentador === 'AL-5' && 'active')}
                to={getLink('alimentador', 'AL-5')}
              >
                AL-5
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                className={clsx(alimentador === 'DL-3' && 'active')}
                to={getLink('alimentador', 'DL-3')}
              >
                DL-3
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                className={clsx(alimentador === 'DL-4' && 'active')}
                to={getLink('alimentador', 'DL-4')}
              >
                DL-4
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="pladial__box p-2">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  className={clsx(tipo === 'automatico' && 'active')}
                  to={getLink('tipo', 'automatico')}
                >
                  Automático
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  className={clsx(isManual && 'active')}
                  to={getLink('tipo', 'manual')}
                >
                  Manual
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="pladial__box--inner p-2">
              <div className="d-flex align-items-end my-3">
                <h4 className="mx-3 mb-4">
                  <strong>250T</strong>
                </h4>
                {tipo === 'manual' && (
                  <div className="w-100 d-flex align-items-end justify-content-between">
                    <Form.Group>
                      <Form.Label>Pila</Form.Label>
                      <Form.Select name="deposito">
                        {depositos?.map((dep: { id: number; descripcion: string }) => (
                          <option key={dep.id} value={dep.id}>
                            {dep.descripcion}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Button variant="primary">Guardar</Button>
                  </div>
                )}
              </div>
              <Table bordered>
                <thead>
                  <tr
                    className={clsx(
                      'pladial__table-header',
                      isManual && 'pladial__table-header--manual',
                    )}
                  >
                    <th>Voladura</th>
                    <th>kTons</th>
                    <th>% Alimentado</th>
                    <th>%CI</th>
                    <th>%FeMag</th>
                    <th>FeTot</th>
                    <th>Azufre</th>
                    <th>Calcio</th>
                    <th>Sílice</th>
                    <th>Fósforo</th>
                    <th>MgO</th>
                    <th>AI2O3</th>
                    <th>kwh/t</th>
                  </tr>
                </thead>
                <tbody>
                  {alimentador &&
                    pladial.turno1[0][alimentador].map(p => (
                      <tr key={p.id}>
                        <td>{p.voladura}</td>
                        <td>{p.ktons}</td>
                        <td>
                          <Form.Control placeholder="0%" value="10%" />
                        </td>
                        <td>{p.ci}</td>
                        <td>{p.femag}</td>
                        <td>{p.fetot}</td>
                        <td>{p.azufre}</td>
                        <td>{p.calcio}</td>
                        <td>{p.silice}</td>
                        <td>{p.fosforo}</td>
                        <td>{p.mgo}</td>
                        <td>{p.al2o3}</td>
                        <td>{p.kwht}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
