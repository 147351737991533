import * as Yup from 'yup';

import { ErrorMessages } from '../../constants/errorMessages';

export const registroUsuarioValidationSchema = isInterno =>
  Yup.object().shape({
    id: Yup.string(),
    userName: Yup.string().required(ErrorMessages.Required),
    email: Yup.string().required(ErrorMessages.Required).email(ErrorMessages.InvalidEmail),
    roles: isInterno
      ? Yup.array()
          .compact(r => !r)
          .min(1, ErrorMessages.AtLeastOne)
          .of(Yup.string().required())
          .required(ErrorMessages.AtLeastOne)
          .nullable()
      : Yup.array().nullable(),
    areaId: isInterno ? Yup.number().nullable() : Yup.number().min(1, ErrorMessages.Required),
  });
