import { useQuery } from '@tanstack/react-query';

import { VoladurasService } from '../client';
import { QueryConfig } from '../config/queryClient';
import { TurnosEnum } from '../models/enums/turnos.enum';

type UseVoladurasProps = {
  fecha: string;
  turno: TurnosEnum;
  config?: QueryConfig<any[]>;
};

export function useVoladuras({ fecha, turno, config = {} }: UseVoladurasProps) {
  return useQuery<any[]>(
    ['voladuras', fecha, turno],
    async () => {
      if (turno !== TurnosEnum.TodoElDia) {
        return VoladurasService.getGetVoladurasPorZonaDepositacion({
          fecha,
          turnoId: turno,
        });
      }

      //? Cuando el turno es "Todo el día", obtiene los datos de todos los demás turnos
      const todosLosTurnos = [
        VoladurasService.getGetVoladurasPorZonaDepositacion({
          fecha,
          turnoId: TurnosEnum.Primero,
        }),
        VoladurasService.getGetVoladurasPorZonaDepositacion({
          fecha,
          turnoId: TurnosEnum.Segundo,
        }),
        VoladurasService.getGetVoladurasPorZonaDepositacion({
          fecha,
          turnoId: TurnosEnum.Tercero,
        }),
      ];
      
      const todosLosTurnosResponse = (await Promise.all(todosLosTurnos)).flat();

      //? Se hace una sumatoria de los tonelajes de las diferentes voladuras.
      const allVoladuras = todosLosTurnosResponse.reduce((acc, current) => {
        const voladura = current.voladura;
        const zona = current.zonaDepositacion;

        if (acc[`${voladura}-${zona}`]) {
          acc[`${voladura}-${zona}`].tonelajeAcumulado += current.tonelajeAcumulado;
        } else {
          acc[`${voladura}-${zona}`] = current;
        }

        return acc;
      }, {});
      
      console.log(allVoladuras)

      return Object.values(allVoladuras);
    },
    config,
  );
}
