import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

import useAlert from '../../hooks/useAlert';
import useDepositoMineral from '../../hooks/useDepositoMineral';
import useError from '../../hooks/useError';
import { IEditarPosicionador } from '../../models/interfaces/voladuras/IEditarPosicionador';
import { ModificadorPosicionadorProps } from '../../models/types/modificar-posicionador.type';
import { editPosicionApilador } from '../../services/voladuras.service';
import { editarPosicionadorValidationSchema } from '../../utils/validations/editar-posicionadorApilador.validation';

const defaultValues: IEditarPosicionador = {
  fecha: new Date().toISOString().slice(0, 10),
  posicion: '',
  horaInicio: '',
  horaFin: '',
};

export default function EditarPosicionador(props: ModificadorPosicionadorProps) {
  const { estadoModal, cerrarModal } = props;
  const { setError, ErrorAlert } = useError();
  const { CustomAlert, setAlert } = useAlert('success');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(editarPosicionadorValidationSchema),
  });

  const { mutate, isLoading } = useMutation(editPosicionApilador, {
    onError: error => setError(error),
    onSuccess: () => {
      setAlert('Los cambios fueron exitosamente guardados');
    },
  });

  const { data: depositos, isLoading: depositosIsLoading } = useDepositoMineral({
    onError: e => setError(e),
  });

  const depositosFiltrados = useMemo(() => {
    const resultados: string[] = [];
    if (!depositos?.length) return resultados;
    const regex = new RegExp('DL-');
    depositos.forEach(deposito => {
      if (regex.test(deposito.descripcion)) {
        resultados.push(deposito.descripcion);
      }
    });

    return resultados;
  }, [depositos]);

  const guardarPosicion: SubmitHandler<IEditarPosicionador> = (response: IEditarPosicionador) =>
    mutate(response);

  useEffect(() => {
    if (!estadoModal) {
      reset(defaultValues);
      setError('');
      setAlert('');
    }
  }, [estadoModal]);

  return (
    <Modal onHide={() => cerrarModal(false)} show={estadoModal}>
      <Form onSubmit={handleSubmit(guardarPosicion)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajustar Posición del Apilador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomAlert />
          <ErrorAlert />
          <Form.Group className="mb-3">
            <Form.Label>Fecha</Form.Label>
            <Form.Control isInvalid={!!errors.fecha} type="date" {...register('fecha')} />
            <Form.Control.Feedback type="invalid">{`${errors?.fecha?.message}`}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Ubicación Posicionador</Form.Label>
            <Form.Select isInvalid={!!errors.posicion} {...register('posicion')}>
              {depositosIsLoading ? (
                <option value="">Cargando depósitos...</option>
              ) : (
                depositosFiltrados?.map(deposito => (
                  <option key={deposito} value={deposito.substring(deposito.lastIndexOf('-') + 1)}>
                    {deposito}
                  </option>
                ))
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{`${errors?.posicion?.message}`}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Hora Inicio</Form.Label>
            <Form.Control
              isInvalid={!!errors.horaInicio}
              step="1"
              type="time"
              {...register('horaInicio')}
            />
            <Form.Control.Feedback type="invalid">{`${errors?.horaInicio?.message}`}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Hora Fin</Form.Label>
            <Form.Control
              isInvalid={!!errors.horaFin}
              step="1"
              type="time"
              {...register('horaFin')}
            />
            <Form.Control.Feedback type="invalid">{`${errors?.horaFin?.message}`}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cerrarModal(false)} variant="secondary">
            Cancelar
          </Button>
          <Button disabled={isLoading} type="submit">
            {isLoading ? (
              <>
                <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
                &nbsp;Guardando
              </>
            ) : (
              'Guardar'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
