import { Container, Tab, Tabs } from 'react-bootstrap';

import Title from '../../components/Title';
import AlimentadorDeposito from './AlimentadorDeposito';
import { CalidadesTrituracionSecundaria } from './CalidadesTrituracionSecundaria';
import Camiones from './Camiones';
import DepositoMineral from './DepositoMineral';
import Turnos from './Turnos';

export default function Catalogos() {
  return (
    <Container className="usuarios d-flex flex-column">
      <Title titulo="Catálogos" />

      <Tabs className="mb-3" defaultActiveKey="camiones" mountOnEnter>
        <Tab eventKey="camiones" title="Camiones">
          <Camiones />
        </Tab>
        <Tab eventKey="deposito" title="Depósito">
          <DepositoMineral />
        </Tab>
        <Tab eventKey="turno" title="Turno">
          <Turnos />
        </Tab>
        <Tab eventKey="alimentadorDeposito" title="Relación AL/DL">
          <AlimentadorDeposito />
        </Tab>
        <Tab eventKey="calidadesTrituracion" title="Calidades Trituración Secundaria">
          <CalidadesTrituracionSecundaria />
        </Tab>
      </Tabs>
    </Container>
  );
}
