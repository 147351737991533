import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';

import Loading from '../../components/Loading';
import EditarTurnoModal from '../../components/modals/EditarTurnoModal';
import EliminarModal from '../../components/modals/EliminarModal';
import useError from '../../hooks/useError';
import { ITurnos } from '../../models/interfaces/catalogos/ICatalogos';
import { deleteTurnoService, getTurnoService } from '../../services/turnos.service';

export default function Turnos() {
  const [editModal, setEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<number>(0);
  const [itemBusqueda, setItemBusqueda] = useState<string>('');
  const [detalle] = useState<ITurnos>({
    id: 0,
    descripcion: '',
    inicio: '',
    termino: '',
  });
  const { ErrorAlert, setError } = useError();
  const { data: turno, isLoading } = useQuery(['turnos'], getTurnoService, {
    onError: e => setError(e),
  });

  // const openModal = () => {
  //   setDetalle({
  //     id: 0,
  //     descripcion: '',
  //     inicio: '',
  //     termino: '',
  //   });
  //   setEditModal(true);
  // };

  // const openEditModal = (values: ITurnos) => {
  //   setDetalle(values);
  //   setEditModal(true);
  // };

  // const openDeleteModal = (values: ITurnos) => {
  //   setDeleteModal(values.id);
  // };

  const buscarTurno = (useParam: string) => {
    setItemBusqueda(useParam);
  };

  return (
    <Container>
      <ErrorAlert />
      {isLoading && <Loading mensaje="Obteniendo turnos" />}
      <Row>
        <Col className="my-3 d-flex align-items-end justify-content-between" sm={6} xs={7}>
          <InputGroup>
            <Form.Control
              name="buscar"
              onChange={({ target }) => buscarTurno(target.value)}
              placeholder="Turno"
              value={itemBusqueda}
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        {/* <Col className="my-3 d-flex align-items-end justify-content-end" xs={5} sm={6}>
          <Button className="text-white" variant="secondary" onClick={() => openModal()}>
            Agregar Nuevo Turno
          </Button>
        </Col> */}
      </Row>
      <Row className="my-5">
        <Col xs={12}>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th>Descripción</th>
                <th>Hora inicio</th>
                <th>Hora termino</th>
              </tr>
            </thead>
            <tbody>
              {turno?.length ? (
                turno
                  .filter(({ descripcion }: ITurnos) => {
                    if (!itemBusqueda) return true;

                    const regex = new RegExp(itemBusqueda, 'gi');
                    return descripcion.match(regex);
                  })
                  .map((turn: ITurnos) => (
                    <tr key={turn.id}>
                      <td>{turn.descripcion}</td>
                      <td>{turn.inicio}</td>
                      <td>{turn.termino}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={4}>No hay turnos registrados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <EditarTurnoModal
        detalle={detalle}
        estadoModalCatalogo={editModal}
        onClose={() => setEditModal(false)}
      />
      <EliminarModal
        confirmationMessage="¿Estas seguro de Eliminar el turno?"
        handleClose={() => setDeleteModal(0)}
        id={deleteModal}
        mutationFn={() => deleteTurnoService(deleteModal)}
        queryKey="turnos"
        title="Eliminar Turno"
      />
    </Container>
  );
}
