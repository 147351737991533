import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import useCamiones from '../../hooks/useCamiones';
import useDepositoMineral from '../../hooks/useDepositoMineral';
import { EditarMovimientoTonelajeValidationSchema } from '../../utils/validations/editar-movimientoTonelaje.validation';

export default function EditarMovimientoTrituracionSecundaria(props: {
  estadoModal: boolean;
  cambiarEstadoModal: Function;
  movimientoTrituracion: any;
  actualizarMovimientoTrituracion: any;
}) {
  const {
    estadoModal,
    cambiarEstadoModal,
    movimientoTrituracion,
    actualizarMovimientoTrituracion,
  } = props;
  const movimientoSeleccionado = movimientoTrituracion?.viajesTrituracionSecundaria?.find(
    viaje => viaje.id === movimientoTrituracion.idMovimiento,
  );
  const { data: camiones } = useCamiones({});
  const { data: depositos } = useDepositoMineral({});

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditarMovimientoTonelajeValidationSchema),
  });

  const guardarMovimiento = requestData => {
    const {
      id,
      turnoId,
      tonelajeAcumulado1,
      tonelajeAcumulado2,
      tonelajeAcumulado3,
      tonelajeAcumulado4,
      tonelajeAcumulado5,
      tonelajeAcumulado6,
      tonelajeAcumulado7,
      tonelajeAcumulado8,
      tonelajeAcumulado9,
      tonelajeAcumulado10,
      tonelajeAcumulado11,
      tonelajeAcumulado12,
      tonelajeAcumulado13,
      tonelajeAcumulado14,
      tonelajeAcumulado15,
      tonelajeAcumulado16,
      tonelajeAcumulado17,
      tonelajeAcumulado18,
      tonelajeAcumulado19,
      tonelajeAcumulado20,
      tonelajeAcumulado21,
      tonelajeAcumulado22,
      tonelajeAcumulado23,
      tonelajeAcumulado24,
      viajesTrituracionSecundaria,
    } = movimientoTrituracion;

    const { camionId, depositoMineralId, toneladas, viajes } = requestData;
    const dataMovimientoTrituracionSecundaria = {
      id,
      turnoId,
      tonelajeAcumulado1,
      tonelajeAcumulado2,
      tonelajeAcumulado3,
      tonelajeAcumulado4,
      tonelajeAcumulado5,
      tonelajeAcumulado6,
      tonelajeAcumulado7,
      tonelajeAcumulado8,
      tonelajeAcumulado9,
      tonelajeAcumulado10,
      tonelajeAcumulado11,
      tonelajeAcumulado12,
      tonelajeAcumulado13,
      tonelajeAcumulado14,
      tonelajeAcumulado15,
      tonelajeAcumulado16,
      tonelajeAcumulado17,
      tonelajeAcumulado18,
      tonelajeAcumulado19,
      tonelajeAcumulado20,
      tonelajeAcumulado21,
      tonelajeAcumulado22,
      tonelajeAcumulado23,
      tonelajeAcumulado24,
      viajesTrituracionSecundaria: viajesTrituracionSecundaria.map(vj =>
        vj.id === movimientoSeleccionado.id
          ? {
              ...vj,
              camionId,
              depositoMineralId,
              toneladas,
              viajes,
              tonelajeEstimado: viajes * camiones.find(cm => cm.id === camionId)?.volumen || 0,
            }
          : vj,
      ),
    };
    actualizarMovimientoTrituracion({ movimientos: [dataMovimientoTrituracionSecundaria] });
  };

  useEffect(() => {
    if (estadoModal) {
      reset({
        camionId: movimientoSeleccionado?.camionId || 1,
        depositoMineralId: movimientoSeleccionado?.depositoMineralId || 1,
        toneladas: movimientoSeleccionado?.toneladas || 0,
        viajes: movimientoSeleccionado?.viajes || 0,
      });
    }
  }, [estadoModal]);

  return (
    <Modal onHide={() => cambiarEstadoModal(false)} show={estadoModal} size="lg">
      <Form onSubmit={handleSubmit(guardarMovimiento)}>
        <Modal.Header closeButton>
          <Modal.Title>Movimiento Trituración Secundaria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col md={6} sm={6} xs={6}>
              <Form.Group>
                <Form.Label>Camión</Form.Label>
                <Form.Select
                  isInvalid={!!errors.camionId}
                  {...register('camionId', {
                    valueAsNumber: true,
                  })}
                >
                  {camiones
                    ?.filter(cm => ['GDI', 'EMD'].includes(cm.areaDescripcion.toUpperCase()))
                    ?.map(camion => (
                      <option key={camion?.id} value={camion?.id}>
                        {camion?.numCamion}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.camionId?.message?.toString()}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={6}>
              <Form.Group>
                <Form.Label>Origen de Alimentación</Form.Label>
                <Form.Select
                  isInvalid={!!errors.depositoMineralId}
                  {...register('depositoMineralId')}
                >
                  {depositos?.map(deposito => (
                    <option key={deposito?.id} value={deposito?.id}>
                      {deposito?.descripcion}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.depositoMineralId?.message?.toString()}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={6}>
              <Form.Group>
                <Form.Label>toneladas</Form.Label>
                <Form.Control
                  isInvalid={!!errors.toneladas}
                  step="0.01"
                  type="number"
                  {...register('toneladas', {
                    valueAsNumber: true,
                  })}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors?.toneladas?.message?.toString()}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={6}>
              <Form.Group>
                <Form.Label>Viajes</Form.Label>
                <Form.Control
                  isInvalid={!!errors.viajes}
                  type="number"
                  {...register('viajes', {
                    valueAsNumber: true,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.viajes?.message?.toString()}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cambiarEstadoModal(false)} variant="secondary">
            Cancelar
          </Button>
          <Button type="submit" variant="primary">
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
