import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Form, Button, Modal, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import useError from '../../hooks/useError';
import { ITurnos } from '../../models/interfaces/catalogos/ICatalogos';
import { EditarModalType } from '../../models/types/modal.type';
import { addTurnoService, editTurnoService } from '../../services/turnos.service';
import { registroTurnoValidationSchema } from '../../utils/validations/registro-turno.validation';

export default function EditarTurnoModal(props: EditarModalType) {
  const { estadoModalCatalogo, onClose, detalle } = props;
  const isEdit = detalle.id;
  const { ErrorAlert, setError } = useError();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(isEdit ? editTurnoService : addTurnoService, {
    onError: e => setError(e),
    onSuccess: () => {
      queryClient.invalidateQueries(['turnos']);
      onClose();
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<ITurnos>({
    resolver: yupResolver(registroTurnoValidationSchema),
  });

  const EditarTurno = async (requestData: ITurnos) => {
    mutate(requestData);
  };

  useEffect(() => {
    if (estadoModalCatalogo) {
      reset(isEdit ? detalle : undefined);
    } else {
      reset({ id: 0, descripcion: '', inicio: '', termino: '' });
    }
    setError('');
  }, [estadoModalCatalogo]);

  return (
    <Modal show={estadoModalCatalogo} onHide={onClose} centered>
      <ErrorAlert />
      <form onSubmit={handleSubmit(EditarTurno)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Editar' : 'Nuevo'} Turno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Col xs={12}>
              <Form.Group className="text-left p-2" controlId="descripcion">
                <Form.Label>Descripcion</Form.Label>
                <Form.Control
                  autoFocus
                  isInvalid={!!errors.descripcion}
                  placeholder="Ingresa el nombre del turno"
                  {...register('descripcion')}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.descripcion?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="text-left p-2" controlId="hora-inicio">
                <Form.Label>Hora de inicio</Form.Label>
                <Form.Control
                  isInvalid={!!errors.inicio}
                  placeholder="Ingresa la hora de inicio"
                  {...register('inicio')}
                  onFocus={e => e.currentTarget.select()}
                  type="time"
                  step="1"
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.inicio?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="text-left p-2" controlId="termino">
                <Form.Label>Termino</Form.Label>
                <Form.Control
                  isInvalid={!!errors.termino}
                  placeholder="Ingresa la hora de termino"
                  {...register('termino')}
                  onFocus={e => e.currentTarget.select()}
                  type="time"
                  step="1"
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.termino?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="br-cg" variant="btn btn-outline-dark" onClick={onClose}>
            Cancelar
          </Button>
          <Button className="text-white" variant="secondary" type="submit">
            {isEdit ? 'Editar' : 'Guardar'} Turno
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
