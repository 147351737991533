import { useQuery } from '@tanstack/react-query';

import { QueryConfig } from '../config/queryClient';
import { ITurno } from '../models/interfaces/turnos/ITurno';
import { getTurnoService } from '../services/turnos.service';

const useTurnos = (config?: QueryConfig<ITurno[]>) =>
  useQuery<ITurno[]>(['turnos'], getTurnoService, { staleTime: Infinity, ...config });

export default useTurnos;
