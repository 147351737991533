export const limpiarAreasTrituracion = (areas: any[]) => {
  if (!!areas?.length) {
    return areas?.filter(
      ({ descripcion }) =>
        descripcion.toLowerCase() !== 'propio' && descripcion.toLowerCase() !== 'mineops',
    );
  }

  return [];
};
