import * as Yup from 'yup';

import { isAfter } from 'date-fns';
import { ErrorMessages } from '../../constants/errorMessages';

export const editarPosicionadorValidationSchema = Yup.object().shape({
  fecha: Yup.string().required(ErrorMessages.Required),
  posicion: Yup.string().required(ErrorMessages.Required),
  horaInicio: Yup.string().required(ErrorMessages.Required),
  horaFin: Yup.string().when('horaInicio', {
    is: (horaInicio) => horaInicio,
    then: Yup.string()
      .test(
        'hora-mayor',
        ErrorMessages.HigherTime,
        function (horaFin) {
          const { horaInicio } = this.parent;
          if (horaInicio && horaFin)
          {
            const [horasInicio, minutosInicio, segundosInicio] = horaInicio.split(':');
            const [horasFin, minutosFin, segundosFin] = horaFin.split(':');
            const inicio = new Date().setHours(+horasInicio, +minutosInicio, +segundosInicio, 0);
            const fin = new Date().setHours(+horasFin, +minutosFin, +segundosFin, 0)
            return isAfter(fin, inicio);
          }
          return false;
        }
      )
      .required(ErrorMessages.Required)
  }).required(ErrorMessages.Required),
});
