import clsx from 'clsx';
import { ReactElement, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import useAreasTrituracion from '../hooks/useAreasTrituracion';
import useFechaTurno from '../hooks/useFechaTurno';
import useTurnos from '../hooks/useTurnos';
import { TurnosEnum } from '../models/enums/turnos.enum';
import { limpiarAreasTrituracion } from '../utils/area-trituracion';

interface FechaTurnoProps {
  leftContent?: ReactElement;
  showAreaTrituracion?: boolean;
  showWholeDayOption?: boolean;
  className?: string;
}

interface ParamsTypes {
  fecha?: string;
  turno?: string;
  areaTrituracion?: string;
}

export default function FechaTurno({
  leftContent,
  showAreaTrituracion,
  showWholeDayOption,
  className,
}: FechaTurnoProps) {
  const { data: turnos } = useTurnos();
  const [searchParams, setSearchParams] = useSearchParams();
  const { fecha, turno, areaTrituracion } = useFechaTurno();
  const { data: areas } = useAreasTrituracion({});

  const setParams = (key: string) => e => {
    setSearchParams({ ...Object.fromEntries(searchParams), [key]: e.target.value });
  };

  useEffect(() => {
    const params: ParamsTypes = {};
    if (!fecha) {
      const today = new Date();
      params.fecha = today.toISOString().substring(0, 10);
    }

    if (!turno && turnos?.length) {
      params.turno = `${turnos[0].id}`;
    }

    if (showAreaTrituracion) {
      params.areaTrituracion = `${areaTrituracion}`;
    }

    if (params.fecha || params.turno || +params.areaTrituracion >= 0) {
      setSearchParams({ ...Object.fromEntries(searchParams), ...params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fecha, turno, turnos, areas, areaTrituracion]);

  return (
    <Row as={Form} className={clsx('mb-4', className)}>
      {leftContent}
      <Col as={Form.Group} md={showAreaTrituracion ? 4 : 5}>
        <Form.Label>Fecha</Form.Label>
        <Form.Control onChange={setParams('fecha')} placeholder="Fecha" type="date" value={fecha} />
      </Col>
      <Col as={Form.Group} md={showAreaTrituracion ? 4 : 5}>
        <Form.Label>Turno</Form.Label>
        <Form.Select onChange={setParams('turno')} value={turno}>
          {showWholeDayOption && <option value={TurnosEnum.TodoElDia}>Todo el día</option>}
          {turnos?.map(({ id, descripcion }) => (
            <option key={id} value={id}>
              {descripcion}
            </option>
          ))}
        </Form.Select>
      </Col>
      {showAreaTrituracion && (
        <Col as={Form.Group} md={4}>
          <Form.Group>
            <Form.Label>Área Trituración</Form.Label>
            <Form.Select
              name="areaTrituracion"
              onChange={setParams('areaTrituracion')}
              value={areaTrituracion}
            >
              <option value={0}>Todas las áreas</option>
              {limpiarAreasTrituracion(areas)?.map?.(({ id, descripcion }) => (
                <option key={id} value={id}>
                  {descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      )}
    </Row>
  );
}
