import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Form, Button, Modal, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAlimentadorD from '../../hooks/useAlimentadorD';
import useDepositoMineral from '../../hooks/useDepositoMineral';

import useError from '../../hooks/useError';
import { IAlimentadorDeposito } from '../../models/interfaces/catalogos/ICatalogos';
import { EditarModalCompuestaType } from '../../models/types/modal.type';
import {
  addAlimentadorDepositoService,
  editAlimentadorDepositoService,
} from '../../services/alimentadorDeposito.service';
import { registroAlimentadorDepositoValidationSchema } from '../../utils/validations/registro-alimentadorDeposito.validation';

export default function EditarAlimentadorDepositoModal(props: EditarModalCompuestaType) {
  const { estadoModalCatalogo, onClose, detalle } = props;

  const isEdit = detalle.id;
  const { data: deposito } = useDepositoMineral({
    onError: e => setError(e),
  });
  const { data: alimentador } = useAlimentadorD({
    onError: e => setError(e),
  });
  const { ErrorAlert, setError } = useError();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    isEdit ? editAlimentadorDepositoService : addAlimentadorDepositoService,
    {
      onError: e => {
        setError(e);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['alimentador-deposito']);
        onClose();
      },
    },
  );

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<IAlimentadorDeposito>({
    resolver: yupResolver(registroAlimentadorDepositoValidationSchema),
  });

  const onSubmit = (requestData: IAlimentadorDeposito) => {
    mutate(requestData);
  };

  useEffect(() => {
    if (estadoModalCatalogo) {
      reset(isEdit ? detalle : undefined);
    } else {
      reset({
        id: 0,
        alimentadorId: 0,
        alimentador: '',
        primerDepositoMineralId: 0,
        segundoDepositoMineralId: 0,
        depositoUno: '',
        depositoDos: '',
        porcentajeUno: 0,
        porcentajeDos: 0,
      });
    }
    setError('');
  }, [estadoModalCatalogo]);

  return (
    <Modal show={estadoModalCatalogo} onHide={onClose} centered>
      <ErrorAlert />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Editar' : 'Nueva'} Relacion Alimentador Deposito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Col xs={12}>
              <Form.Group className="text-left p-2" controlId="alimentadorId">
                <Form.Label>Alimentador</Form.Label>
                <Form.Select {...register('alimentadorId')}>
                  {alimentador?.map(({ id, descripcion }) => (
                    <option key={id} value={id}>
                      {descripcion}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.alimentadorId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="text-left p-2" controlId="segundoDepositoMineralId">
                <Form.Label>Deposito Mineral</Form.Label>
                <Form.Select {...register('primerDepositoMineralId')}>
                  {deposito?.map(({ id, descripcion }) => (
                    <option key={id} value={id}>
                      {descripcion}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.primerDepositoMineralId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="text-left p-2" controlId="porcentajeUno">
                <Form.Label>porcentaje</Form.Label>
                <Form.Control
                  isInvalid={!!errors.porcentajeUno}
                  placeholder="Ingresa el porcentaje"
                  type="number"
                  max={100}
                  min={0}
                  {...register('porcentajeUno')}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.porcentajeUno?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="text-left p-2" controlId="segundoDepositoMineralId">
                <Form.Label>Deposito Mineral dos</Form.Label>
                <Form.Select {...register('segundoDepositoMineralId')}>
                  {deposito?.map(({ id, descripcion }) => (
                    <option key={id} value={id}>
                      {descripcion}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.segundoDepositoMineralId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="text-left p-2" controlId="porcentajeDos">
                <Form.Label>porcentaje dos</Form.Label>
                <Form.Control
                  isInvalid={!!errors.porcentajeDos}
                  placeholder="Ingresa el porcentaje"
                  type="number"
                  max={100}
                  min={0}
                  {...register('porcentajeDos')}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.porcentajeDos?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="br-cg" variant="btn btn-outline-dark" onClick={onClose}>
            Cancelar
          </Button>
          <Button className="text-white" variant="secondary" type="submit">
            {isEdit ? 'Editar' : 'Agregar'} Relacion Alimentador Deposito
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
