import {
  IRegistroDepositoMineral,
  IDepositoMineral,
} from '../models/interfaces/catalogos/ICatalogos';
import http from '../utils/http';

export const addDepositoMineralService = async (dataRequest: IRegistroDepositoMineral) => {
  try {
    const response = await http.post('DepositoMineral/RegistrarDepositoMineral', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editDepositoMineralService = async (dataRequest: IDepositoMineral) => {
  try {
    const response = await http.put(
      `DepositoMineral/EditarDepositoMineral/${dataRequest.id}`,
      dataRequest,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getDepositoMineralService = async () => {
  try {
    const response = await http.get('DepositoMineral/ObtenerDepositoMineral');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getByIdDepositoMineralService = async (idDepositoMineral: number) => {
  try {
    const response = await http.get(`DepositoMineral/ObtenerDepositoMineral/${idDepositoMineral}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteDepositoMineralService = async (id: number) => {
  try {
    const response = await http.delete(`DepositoMineral/EliminarDepositoMineral/${id}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
