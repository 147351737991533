import EditIcon from '@mui/icons-material/Edit';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Loading from '../components/Loading';
import MensajeComponent from '../components/Mensaje';
import Title from '../components/Title';
import { ROUTES } from '../constants/routes';
import useError from '../hooks/useError';
import useUsuarios from '../hooks/useUsuarios';
import { IMensaje } from '../models/IMensaje';
import { IUsuario } from '../models/interfaces/usuarios/IUsuario';

import ConfirmacionModal from '../components/modals/ConfirmacionModal';
import { TIPO_USUARIO } from '../models/enums/tipoUsuario.enum';
import { resetPasswordUsuarioExternoService } from '../services/usuarios.service';
import '../styles/Usuarios.scss';

export default function Usuarios() {
  const [itemBusqueda, setItemBusqueda] = useState<string>('');
  const [usurioResetPassword, setUsuarioResetPassword] = useState<IUsuario>(null);
  const [mostrarAlerta, setMostrarAlerta] = useState<{ message: string, variant: string }>(null);
  const [mensaje, setMensaje] = useState<IMensaje>({
    abierto: false,
    mensaje: '',
  });
  const { ErrorAlert, setError } = useError();
  const { data: usuarios, isLoading } = useUsuarios({
    onError: e => setError(e),
  });

  const regex = itemBusqueda ? new RegExp(itemBusqueda, 'gi') : null;

  const onHandleCloseConfirm = () => {
    setMostrarAlerta({ message: 'Se ha reseteado contraseña con éxito', variant: 'success' });
    setUsuarioResetPassword(null)
  }

  const onHandleOpenConfirm = (user: IUsuario) => {
    setMostrarAlerta(null);
    setUsuarioResetPassword(user)
  }

  return (
    <Container className="usuarios d-flex flex-column">
      <ErrorAlert />
      {mensaje.abierto && (
        <MensajeComponent
          mensaje={mensaje.mensaje}
          abierto={mensaje.abierto}
          estadoMensaje={setMensaje}
        />
      )}
      <Title titulo="Usuarios" />
      {isLoading && <Loading mensaje="Cargando Usuarios" />}
      <Row className="mt-5">
        <Col className="my-3 d-flex align-items-end justify-content-between" xs={7} sm={6}>
          <InputGroup>
            <Form.Control
              value={itemBusqueda}
              onChange={({ target }) => setItemBusqueda(target.value)}
              placeholder="Usuario"
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-3 d-flex align-items-end justify-content-end" xs={5} sm={6}>
          <Button
            className="mx-2"
            // @ts-ignore
            as={Link}
            to={`${ROUTES.REGISTRAR_USUARIO}/${TIPO_USUARIO.INTERNO.toLowerCase()}`}
            variant="secondary"
          >
            Nuevo Usuario
          </Button>
          {/* @ts-ignore */}
          <Button as={Link} to={`${ROUTES.REGISTRAR_USUARIO}/${TIPO_USUARIO.TRITURACION_SECUNDARIA.toLowerCase()}`} variant="secondary">
            Nuevo Usuario Trituración Secundaria
          </Button>
        </Col>
      </Row>
      <Row className="my-5">
        {
          mostrarAlerta?.message && 
          <Col>
            <Alert variant={mostrarAlerta?.variant} onClose={() => setMostrarAlerta(null)} dismissible>
              {mostrarAlerta?.message}
            </Alert>
          </Col>
        }
        <Col xs={12}>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Usuario</th>
                <th>Correo Electrónico</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {usuarios?.length > 0 ? (
                usuarios
                  .filter((u: IUsuario) => {
                    if (!itemBusqueda) return true;

                    return u.userName.match(regex);
                  })
                  .map((usr: IUsuario) => (
                    <tr key={usr.id}>
                      <td>{usr.tipo}</td>
                      <td>{usr.userName}</td>
                      <td>{usr.email}</td>
                      <td>
                        <Tooltip title="Editar">
                          <IconButton component={Link} to={`${ROUTES.EDITAR_USUARIO}/${usr.id}/${usr.tipo}`}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {usr.tipo?.toLowerCase() === TIPO_USUARIO.TRITURACION_SECUNDARIA.toLowerCase() &&
                        <Tooltip title="Resetear">
                          <IconButton onClick={() => onHandleOpenConfirm(usr)}>
                            <RotateLeftIcon />
                          </IconButton>
                        </Tooltip> 
                        }
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={4}>No hay usuarios registrados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ConfirmacionModal
        confirmationMessage={`¿Estas seguro de resetear contraseña al usuario ${usurioResetPassword?.userName}?`}
        handleCloseSuccess={onHandleCloseConfirm}
        handleClose={() => setUsuarioResetPassword(null)}
        mutationParameter={usurioResetPassword?.id}
        mutationFn={resetPasswordUsuarioExternoService}
        title="Confirmar resetear contraseña"
      />
    </Container>
  );
}
