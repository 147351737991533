import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';

import Loading from '../../components/Loading';
import EditarCamionModal from '../../components/modals/EditarCamionModal';
import EliminarModal from '../../components/modals/EliminarModal';
import useCamiones from '../../hooks/useCamiones';
import useError from '../../hooks/useError';
import { ICamiones } from '../../models/interfaces/catalogos/ICatalogos';
import { deleteCamionService } from '../../services/camiones.service';

export default function Camiones() {
  const [editModal, setEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<number>(0);
  const [itemBusqueda, setItemBusqueda] = useState<string>('');
  const [detalle, setDetalle] = useState<ICamiones>({
    id: 0,
    numCamion: '',
    areaTrituracionEntityId: 0,
    areaDescripcion: '',
    volumen: 0,
  });
  const { ErrorAlert, setError } = useError();
  const { data: camion, isLoading } = useCamiones({
    onError: e => setError(e),
  });

  const openModal = () => {
    setDetalle({
      id: 0,
      numCamion: '',
      areaTrituracionEntityId: 0,
      areaDescripcion: '',
      volumen: 0,
    });
    setEditModal(true);
  };

  const openEditModal = (values: ICamiones) => {
    setDetalle(values);
    setEditModal(true);
  };

  const openDeleteModal = (values: ICamiones) => {
    setDeleteModal(values.id);
  };

  const buscarCamion = (useParam: string) => {
    setItemBusqueda(useParam);
  };

  return (
    <Container>
      <ErrorAlert />
      {isLoading && <Loading mensaje="Obteniendo camiones" />}
      <Row>
        <Col className="my-3 d-flex align-items-end justify-content-between" sm={6} xs={7}>
          <InputGroup>
            <Form.Control
              name="buscar"
              onChange={({ target }) => buscarCamion(target.value)}
              placeholder="Camión"
              value={itemBusqueda}
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-3 d-flex align-items-end justify-content-end" sm={6} xs={5}>
          <Button className="text-white" onClick={() => openModal()} variant="secondary">
            Agregar Nuevo Camión
          </Button>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12}>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th>Tipo de Camión</th>
                <th>Num. Camión</th>
                <th>Volúmen (tons)</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {camion?.length ? (
                camion
                  .filter(({ numCamion }: ICamiones) => {
                    if (!itemBusqueda) return true;

                    const regex = new RegExp(itemBusqueda, 'gi');
                    return numCamion.match(regex);
                  })
                  .map((cam: ICamiones) => (
                    <tr key={cam.id}>
                      <td>{cam.areaDescripcion}</td>
                      <td>{cam.numCamion}</td>
                      <td>{cam.volumen}</td>
                      <td>
                        <IconButton
                          onClick={() => {
                            openEditModal(cam);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => openDeleteModal(cam)}>
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={4}>No hay camiones registrados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <EditarCamionModal
        detalle={detalle}
        estadoModalCatalogo={editModal}
        onClose={() => setEditModal(false)}
      />
      <EliminarModal
        confirmationMessage="¿Estas seguro de Eliminar el Camion y todos sus datos?"
        handleClose={() => setDeleteModal(0)}
        id={deleteModal}
        mutationFn={() => deleteCamionService(deleteModal)}
        queryKey="camiones"
        title="Eliminar Camion"
      />
    </Container>
  );
}
