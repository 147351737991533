import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FechaTurno from '../components/FechaTurno';
import Loading from '../components/Loading';
import EditarMovimientoTrituracionSecundaria from '../components/modals/EditarMovimientoTrituracionSecundaria';
import { useDataTrituracionSecundaria } from '../hooks/trituracion-secundaria.hooks';
import useColeccionTrituracionSecundaria from '../hooks/useColeccionTrituracionSecundaria';
import useError from '../hooks/useError';
import useFechaTurno from '../hooks/useFechaTurno';
import useReporteTrituracionSecundaria from '../hooks/useReporteTrituracionSecundaria';
import useTurnos from '../hooks/useTurnos';
import { TurnosEnum } from '../models/enums/turnos.enum';
import {
  IColeccionTrituracionSecundaria,
  IEditarTrituracionSecundaria,
  IMovimientoTrituracionSecundaria,
} from '../models/interfaces/ITrituracionSecundaria/IMovimientosTrituracionSecundaria';
import { editMovimientoTrituracionSecundariaService } from '../services/movimientoTrituracionSecundaria.service';
import { downloadFile } from '../utils/download-file';
import {
  defaultTons,
  defaultTph,
  movientoTrituracionSecundariaDefault,
} from '../utils/mocks/trituracion-secundaria.mock';
import style from './Registros/RegistroTrituracionSecundaria.module.scss';

export default function TrituracionSecundaria() {
  const { data: turnos } = useTurnos();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modalMovimiento, setModalMovimiento] = useState<boolean>(false);
  const [movimientoTrituracion, setMovimientoTrituracion] = useState<any>(null);
  const { turno, fecha, areaTrituracion } = useFechaTurno();
  const { setError, ErrorAlert } = useError();

  const {
    data = {},
    isLoading: loadingTodoElDia,
    refetch: refetchTrituracionSecundariaTodoElDia,
  } = useDataTrituracionSecundaria({
    fecha,
    turnoId: turno,
    areaTrituracion,
    config: { enabled: Boolean((fecha && turno) || areaTrituracion) },
  });

  const {
    data: dataTrituracionSecundaria,
    isLoading,
    refetch: refetchTrituracionSecundaria,
  } = useColeccionTrituracionSecundaria({
    fecha,
    turnoId: turno,
    areaTrituracion,
  });

  const { data: reporteTrituracionSecundaria, refetch } = useReporteTrituracionSecundaria({
    fecha,
    turnoId: 0,
    areaTrituracion,
    // config: {enabled: false}
  });

  const { register, handleSubmit, reset } = useForm();
  const isNotTodoElDia = turno !== TurnosEnum.TodoElDia;

  const { tableHeader, horasDelTurno } = useMemo(() => {
    const array = Array.from({
      length: turno !== TurnosEnum.TodoElDia ? 24 / turnos?.length || 8 : 24,
    });
    let startingHour = 6;

    if (turno && turnos) {
      if (isNotTodoElDia) {
        const currentTurno = turnos.find(({ id }) => id === +turno);
        startingHour = +currentTurno.inicio.substring(0, 2);
        const horas = array?.map((_, i) => (i + startingHour) % 24)?.map(val => val + 1);

        return {
          tableHeader: [currentTurno.descripcion],
          horasDelTurno: horas,
        };
      } else {
        const horas = array?.map((_, i) => (i + startingHour) % 24)?.map(val => val + 1);
        return {
          tableHeader: turnos?.map(t => t.descripcion),
          horasDelTurno: horas,
        };
      }
    }

    return { tableHeader: [], horasDelTurno: array as number[] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turno, turnos]);

  const obtenerDataAcumuladoTph = () => {
    if (dataTrituracionSecundaria?.length) {
      return dataTrituracionSecundaria?.map((trit: IColeccionTrituracionSecundaria) => {
        return {
          acumulados: horasDelTurno?.map(turn => trit[`tonelajeAcumulado${turn}`]),
          tphs: horasDelTurno?.map(turn => trit[`tpH${turn}`]),
        };
      });
    } else {
      return Array.from({ length: 24 / turnos?.length || 8 })?.map(() => ({
        acumulados: Array.from({ length: 24 / turnos?.length || 8 })?.map(() => 0),
        tphs: Array.from({ length: 24 / turnos?.length || 8 })?.map(() => 0),
      }));
    }
  };

  const datosTodoElDia = useMemo(() => {
    const acumulados = {} as typeof defaultTons;
    const tphs = {} as typeof defaultTph;

    if (!turnos?.length || !Object.keys(data)) return null;

    const horasPorTurno = Array.from({
      length: 24 / turnos?.length || 8,
    });

    Object.keys(data).forEach(key => {
      const itemsPorTurno =
        data[key].length > 0 ? data[key] : [movientoTrituracionSecundariaDefault];
      const turnoMov = turnos.find(t => t.id === TurnosEnum[key]);
      const startingHour = +turnoMov.inicio.substring(0, 2);
      const horas = horasPorTurno?.map((_, i) => (i + startingHour) % 24)?.map(val => val + 1);

      itemsPorTurno.forEach((mov, idx) => {
        if (!acumulados[idx]) acumulados[idx] = { ...defaultTons };
        if (!tphs[idx]) tphs[idx] = { ...defaultTph };

        const currentAcumulado = acumulados[idx];
        const currentTph = tphs[idx];

        if (!currentAcumulado.areaTrituracionSecundaria) {
          currentAcumulado.areaTrituracionSecundaria = mov.areaTrituracionSecundaria;
        }

        if (!currentTph.areaTrituracionSecundaria) {
          currentTph.areaTrituracionSecundaria = mov.areaTrituracionSecundaria;
        }

        horas.forEach(hora => {
          const tonProp = `tonelajeAcumulado${hora}`;
          const tphProp = `tpH${hora}`;

          currentAcumulado[tonProp] = mov[tonProp];
          currentTph[tphProp] = mov[tphProp];
        });
      });
    });

    const array = Array.from({
      length: turno !== TurnosEnum.TodoElDia ? 24 / turnos?.length || 8 : 24,
    });
    const horas = array?.map((_, i) => (i + 6) % 24)?.map(val => val + 1);

    const result = Object.keys(acumulados)?.map(key => {
      const acumDelMov = acumulados[key];
      const tphDelMov = tphs[key];
      const totalAcumDia = Math.max.apply(
        null,
        horas?.map(hora => acumDelMov[`tonelajeAcumulado${hora}`]),
      );

      return {
        totalAcumDia,
        component: (
          <Fragment key={key}>
            {
              <tr className={clsx(style.tableRow, 'text-center')}>
                <td className={clsx(style.tableHeader, 'position-sticky start-0')}>
                  Acumulado ({acumDelMov.areaTrituracionSecundaria})
                </td>
                {horas?.map((hora, index) => (
                  <td key={index}>{acumDelMov[`tonelajeAcumulado${hora}`] || 0}</td>
                ))}
              </tr>
            }
            <tr className={clsx(style.tableRow, 'text-center')}>
              <td className={clsx(style.tableHeader, 'position-sticky start-0')}>
                TPH ({acumDelMov.areaTrituracionSecundaria})
              </td>

              {horas?.map((hora, index) => (
                <td key={`${hora}-${index}`}>{tphDelMov[`tpH${hora}`] || 0}</td>
              ))}
            </tr>

            <tr className={clsx(style.tableRow, 'text-center')}>
              <td className={clsx(style.tableHeader, 'position-sticky start-0')}>
                Tons ({acumDelMov.areaTrituracionSecundaria})
              </td>
              <td colSpan={24}>{totalAcumDia} TONS por día</td>
            </tr>
          </Fragment>
        ),
      };
    });

    return result;
  }, [data, turno, turnos]);

  const viajesTrituracionSecundaria = Object.values(data)
    .flat()
    ?.map((tri: IColeccionTrituracionSecundaria) => {
      return tri.viajesTrituracionSecundaria?.map(valor => ({
        ...valor,
        areaTrituracion: tri.areaTrituracionSecundaria,
        idTrituracionSecundaria: tri.id,
      }));
    })
    .flat();

  const guardarTrituracionSecundaria = (dataRequestTrituracionSecundaria: any) => {
    if (isEdit) {
      const movimientos: IEditarTrituracionSecundaria[] = dataTrituracionSecundaria?.map(
        (trit: any, index: number) => {
          const tonelajesAcumulados = Object.values(dataRequestTrituracionSecundaria)?.map(
            (valores: number[]) => valores[index],
          );
          horasDelTurno.forEach((turno: number, indiceTurno: number) => {
            trit[`tonelajeAcumulado${turno}`] = tonelajesAcumulados[indiceTurno];
          });
          const {
            id,
            turnoId,
            tonelajeAcumulado1,
            tonelajeAcumulado2,
            tonelajeAcumulado3,
            tonelajeAcumulado4,
            tonelajeAcumulado5,
            tonelajeAcumulado6,
            tonelajeAcumulado7,
            tonelajeAcumulado8,
            tonelajeAcumulado9,
            tonelajeAcumulado10,
            tonelajeAcumulado11,
            tonelajeAcumulado12,
            tonelajeAcumulado13,
            tonelajeAcumulado14,
            tonelajeAcumulado15,
            tonelajeAcumulado16,
            tonelajeAcumulado17,
            tonelajeAcumulado18,
            tonelajeAcumulado19,
            tonelajeAcumulado20,
            tonelajeAcumulado21,
            tonelajeAcumulado22,
            tonelajeAcumulado23,
            tonelajeAcumulado24,
            viajesTrituracionSecundaria,
          } = trit;
          return {
            id,
            turnoId,
            tonelajeAcumulado1,
            tonelajeAcumulado2,
            tonelajeAcumulado3,
            tonelajeAcumulado4,
            tonelajeAcumulado5,
            tonelajeAcumulado6,
            tonelajeAcumulado7,
            tonelajeAcumulado8,
            tonelajeAcumulado9,
            tonelajeAcumulado10,
            tonelajeAcumulado11,
            tonelajeAcumulado12,
            tonelajeAcumulado13,
            tonelajeAcumulado14,
            tonelajeAcumulado15,
            tonelajeAcumulado16,
            tonelajeAcumulado17,
            tonelajeAcumulado18,
            tonelajeAcumulado19,
            tonelajeAcumulado20,
            tonelajeAcumulado21,
            tonelajeAcumulado22,
            tonelajeAcumulado23,
            tonelajeAcumulado24,
            viajesTrituracionSecundaria,
          };
        },
      );
      mutate({ movimientos });
    } else {
      setIsEdit(true);
    }
  };

  const { isLoading: isUpdating, mutate } = useMutation({
    mutationFn: async (requestData: { movimientos: IEditarTrituracionSecundaria[] }) =>
      await editMovimientoTrituracionSecundariaService(requestData),
    onSuccess: () => {
      setIsEdit(false);
      setModalMovimiento(false);
      reset();
      refetchTrituracionSecundaria();
      refetchTrituracionSecundariaTodoElDia();
    },
    onError: error => {
      setError(error);
      setModalMovimiento(false);
      setIsEdit(false);
      reset();
    },
  });

  const abrirModalEditarViaje = (movimiento: {
    idTrituracionSecundaria: number;
    idMovimiento: number;
  }) => {
    const trituracionSecundariaSeleccionada = dataTrituracionSecundaria.find(
      tr => tr.id === movimiento.idTrituracionSecundaria,
    );
    if (trituracionSecundariaSeleccionada) {
      setMovimientoTrituracion({
        ...trituracionSecundariaSeleccionada,
        idMovimiento: movimiento.idMovimiento,
      });
      setModalMovimiento(true);
    }
  };

  useEffect(() => {
    if (reporteTrituracionSecundaria) downloadFile(reporteTrituracionSecundaria);
  }, [reporteTrituracionSecundaria]);

  return (
    <Container className="d-flex flex-column" fluid>
      {(isLoading || isUpdating || loadingTodoElDia) && (
        <Loading
          mensaje={`${
            isLoading || loadingTodoElDia ? 'Cargando' : 'Editando'
          } Trituraciones Secundarias`}
        />
      )}

      <ErrorAlert />

      <Form onSubmit={handleSubmit(guardarTrituracionSecundaria)}>
        <Row>
          <Col xs={9}>
            <Row className="align-items-center">
              <Col xs={9}>
                <FechaTurno showAreaTrituracion showWholeDayOption />
              </Col>
              {!isNotTodoElDia && (
                <Col xs={3}>
                  <strong>
                    {datosTodoElDia?.reduce((sum, next) => sum + next.totalAcumDia, 0).toFixed(2)}{' '}
                    TONS por día
                  </strong>
                </Col>
              )}
            </Row>
          </Col>
          <Col className="d-flex align-items-center justify-content-end" xs={3}>
            <Button onClick={() => refetch()}>Descargar Reporte</Button>&nbsp;
            {isNotTodoElDia && (
              <Button disabled={!Object.keys(data)?.length} type="submit">
                {isEdit ? 'Guardar Trituración Secundaria' : 'Editar Trituración Secundaria'}
              </Button>
            )}
          </Col>
        </Row>

        <Row className="g-0">
          <Col className="position-relative overflow-x-scroll" xs={12}>
            <Table bordered>
              <tr className={style.tableHeader}>
                <th className={clsx(style.tableHeader, 'position-sticky start-0')}>Turno</th>
                {tableHeader?.map(header => (
                  <th key={header} colSpan={8}>
                    {header}
                  </th>
                ))}
              </tr>

              {/* Horas en el turno */}
              {horasDelTurno && (
                <tr className={clsx(style.tableRow, 'text-center fw-bold')}>
                  <th className={clsx(style.tableHeader, 'position-sticky start-0')}>Hora</th>
                  {horasDelTurno?.map(t => (
                    <td key={t}>{t}</td>
                  ))}
                </tr>
              )}

              {turno === TurnosEnum.TodoElDia && datosTodoElDia?.map(item => item.component)}

              {turno !== TurnosEnum.TodoElDia &&
                (dataTrituracionSecundaria?.length
                  ? dataTrituracionSecundaria
                  : [movientoTrituracionSecundariaDefault]
                )?.map((mov: IColeccionTrituracionSecundaria, index: number) => (
                  <Fragment key={mov.id}>
                    {horasDelTurno && (
                      <tr className={clsx(style.tableRow, 'text-center')}>
                        <td className={clsx(style.tableHeader, 'position-sticky start-0')}>
                          Acumulado ({mov.areaTrituracionSecundaria})
                        </td>
                        <>
                          {isEdit ? (
                            <>
                              {obtenerDataAcumuladoTph()?.[index]?.acumulados?.map(
                                (tone: number, indexTonelaje: number) => (
                                  <td key={`${tone}-${indexTonelaje}`}>
                                    <Form.Group>
                                      <Form.Control
                                        min={0}
                                        step="0.01"
                                        type="number"
                                        {...register(
                                          `tonelajeAcumulado${horasDelTurno[indexTonelaje]}.${index}`,
                                          {
                                            valueAsNumber: true,
                                            value: tone || 0,
                                            onChange: value => value,
                                          },
                                        )}
                                      />
                                    </Form.Group>
                                  </td>
                                ),
                              )}
                            </>
                          ) : (
                            <>
                              {obtenerDataAcumuladoTph()?.[index]?.acumulados?.map(
                                (ton: number, index: number) => (
                                  <td key={index}>{ton}</td>
                                ),
                              )}
                            </>
                          )}
                        </>
                      </tr>
                    )}
                    <tr className={clsx(style.tableRow, 'text-center')}>
                      <td className={clsx(style.tableHeader, 'position-sticky start-0')}>
                        TPH ({mov.areaTrituracionSecundaria})
                      </td>
                      <>
                        {obtenerDataAcumuladoTph()[index]?.tphs?.map(
                          (tonAcum: number, index: number) => (
                            <td key={`${tonAcum}-${index}`}>{tonAcum}</td>
                          ),
                        )}
                      </>
                    </tr>
                    <tr className={clsx(style.tableRow, 'text-center')}>
                      <td className={clsx(style.tableHeader, 'position-sticky start-0')}>
                        Tons ({mov.areaTrituracionSecundaria})
                      </td>
                      <td colSpan={isNotTodoElDia ? 8 : 24}>
                        {(obtenerDataAcumuladoTph()[index]?.acumulados[7] || 0).toFixed(2)} TONS por
                        {isNotTodoElDia ? ' turno' : ' día'}
                      </td>
                    </tr>
                  </Fragment>
                ))}
            </Table>
          </Col>
        </Row>

        <p className="mt-2 fw-semibold">
          Registro de viajes para alimentación de la trituración secundaria
        </p>

        <Row className="mt-4">
          <Col xs={12}>
            <Table bordered>
              <thead>
                <tr className="primary-header">
                  <th>Camión</th>
                  <th>Origen De Alimentación</th>
                  <th>Toneladas</th>
                  <th>Viajes</th>
                  <th>Área</th>
                  <th>Tonelaje Estimado</th>
                  {isNotTodoElDia && <th>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {viajesTrituracionSecundaria?.length ? (
                  <>
                    {viajesTrituracionSecundaria?.map((mv: IMovimientoTrituracionSecundaria) => (
                      <tr key={mv.id}>
                        <td>{mv.noCamion}</td>
                        <td>{mv.depositoMineralDescripcion}</td>
                        <td className={style.textoNumeroCelda}>{mv.toneladas?.toFixed(2)}</td>
                        <td className={style.textoNumeroCelda}>{mv.viajes}</td>
                        <td>{mv.areaTrituracion}</td>
                        <td className={style.textoNumeroCelda}>
                          {mv.tonelajeEstimado?.toFixed(2)}
                        </td>
                        {isNotTodoElDia && (
                          <td>
                            <IconButton
                              onClick={() =>
                                abrirModalEditarViaje({
                                  idTrituracionSecundaria: mv.idTrituracionSecundaria,
                                  idMovimiento: mv.id,
                                })
                              }
                              size="small"
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </td>
                        )}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      No hay movimientos de trituracion registrados.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Form>

      <EditarMovimientoTrituracionSecundaria
        actualizarMovimientoTrituracion={mutate}
        cambiarEstadoModal={setModalMovimiento}
        estadoModal={modalMovimiento}
        movimientoTrituracion={movimientoTrituracion}
      />
    </Container>
  );
}
