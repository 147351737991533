import * as Yup from 'yup';

import { ErrorMessages } from '../../constants/errorMessages';

export const cambiarContrasenaSchema = Yup.object().shape({
    oldPassword: Yup.string().required(ErrorMessages.Required),
    newPassword: Yup.string().required(ErrorMessages.Required)
        .min(6, ErrorMessages.MinValue(6)),
    repeatPassword: Yup.string().required(ErrorMessages.Required)
        .min(6, ErrorMessages.MinValue(6))
        .oneOf([Yup.ref('newPassword'), null], ErrorMessages.Match('contreseña')),
});