import { IAlimentadorDeposito } from '../models/interfaces/catalogos/ICatalogos';
import http from '../utils/http';

export const addAlimentadorDepositoService = async (dataRequest: IAlimentadorDeposito) => {
  try {
    const response = await http.post('DepositoMineral/RegistrarAlimentadorMineral', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editAlimentadorDepositoService = async (dataRequest: IAlimentadorDeposito) => {
  try {
    const response = await http.put(
      `DepositoMineral/EditarAlimentadorDeposito/${dataRequest.id}`,
      dataRequest,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getAlimentadorDepositoService = async () => {
  try {
    const response = await http.get('DepositoMineral/ObtenerAlimentadoresDepositos');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteAlimentadorDepositoService = async (id: number) => {
  try {
    const response = await http.delete(`DepositoMineral/EliminarAlimentadorDeposito/${id}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getAlimentadorService = async () => {
  try {
    const response = await http.get('DepositoMineral/ObtenerAlimentadores');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
