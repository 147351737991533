import { Container } from 'react-bootstrap';

import TitleComponent from '../components/Title';

export default function BaseDeDatos() {
  return (
    <Container className="d-flex flex-column">
      <TitleComponent titulo="Base de Datos" />
    </Container>
  );
}
