export const ROUTES = {
  BASE_DE_DATOS: '/base-de-datos',
  CALIDAD: '/calidad',
  EDITAR_USUARIO: 'editar-usuario',
  DASHBOARD: '/dashboard',
  MOVIMIENTO_MINERAL: '/movimiento-mineral',
  NO_ENCONTRADO: '*',
  PLADIAL: '/pladial',
  TRITURACION_SECUNDARIA: '/trituracion-secundaria',
  REGISTRAR_USUARIO: 'registrar-usuario',
  ROOT: '/',
  VOLADURAS: '/voladuras',
  USUARIOS: '/usuarios',
  CATALOGOS: '/catalogos',
  REGISTROS: '/registros',
  CAMIONES: '/camiones',
  DEPOSITOS: '/depositos',
  TURNOS: '/turnos',
  REGISTRO_VIAJES: '/registro-viajes',
  CAMBIAR_CONTRASENA: '/cambiar-contrasena',
};
