import { useSearchParams } from 'react-router-dom';

const useFechaTurno = () => {
  const [searchParams] = useSearchParams();
  const fecha = searchParams.get('fecha');
  const turno = +searchParams.get('turno');
  const areaTrituracion = +searchParams.get('areaTrituracion');

  return { fecha, turno, areaTrituracion };
};

export default useFechaTurno;
