import { IColeccionTrituracionSecundaria } from '../../models/interfaces/ITrituracionSecundaria/IMovimientosTrituracionSecundaria';

export const defaultTons = {
  areaTrituracionSecundaria: '',
  tonelajeAcumulado1: 0,
  tonelajeAcumulado2: 0,
  tonelajeAcumulado3: 0,
  tonelajeAcumulado4: 0,
  tonelajeAcumulado5: 0,
  tonelajeAcumulado6: 0,
  tonelajeAcumulado7: 0,
  tonelajeAcumulado8: 0,
  tonelajeAcumulado9: 0,
  tonelajeAcumulado10: 0,
  tonelajeAcumulado11: 0,
  tonelajeAcumulado12: 0,
  tonelajeAcumulado13: 0,
  tonelajeAcumulado14: 0,
  tonelajeAcumulado15: 0,
  tonelajeAcumulado16: 0,
  tonelajeAcumulado17: 0,
  tonelajeAcumulado18: 0,
  tonelajeAcumulado19: 0,
  tonelajeAcumulado20: 0,
  tonelajeAcumulado21: 0,
  tonelajeAcumulado22: 0,
  tonelajeAcumulado23: 0,
  tonelajeAcumulado24: 0,
} as const;

export const defaultTph = {
  areaTrituracionSecundaria: '',
  tpH1: 0,
  tpH2: 0,
  tpH3: 0,
  tpH4: 0,
  tpH5: 0,
  tpH6: 0,
  tpH7: 0,
  tpH8: 0,
  tpH9: 0,
  tpH10: 0,
  tpH11: 0,
  tpH12: 0,
  tpH13: 0,
  tpH14: 0,
  tpH15: 0,
  tpH16: 0,
  tpH17: 0,
  tpH18: 0,
  tpH19: 0,
  tpH20: 0,
  tpH21: 0,
  tpH22: 0,
  tpH23: 0,
  tpH24: 0,
} as const;

export const movientoTrituracionSecundariaDefault: IColeccionTrituracionSecundaria = {
  id: 0,
  fecha: '',
  turnoId: 0,
  turnoName: '',
  usuarioId: '',
  usuarioName: '',
  areaTrituracionSecundaria: '',
  ...defaultTons,
  ...defaultTph,
  viajesTrituracionSecundaria: [
    {
      id: 0,
      depositoMineralId: 0,
      depositoMineralDescripcion: '',
      camionId: 0,
      noCamion: '',
      movimientoTrituracionSecundariaId: 0,
      viajes: 0,
      toneladas: 0,
      tonelajeEstimado: 0,
      areaTrituracion: '',
      idTrituracionSecundaria: 0,
    },
  ],
};
