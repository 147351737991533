import { Button, Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';

import FechaTurno from '../components/FechaTurno';

import '../styles/Dashboard.scss';

// const alimentadores = [
//   { alimentador: 'AL-5', etiqueta: 'Alimentador 5', valores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
// ];

function Alimentador() {
  return (
    <OverlayTrigger
      overlay={
        <Popover>
          <Popover.Header>Alimentador 1</Popover.Header>
          <Popover.Body className="p-0">
            <table className="w-100">
              <tbody>
                <tr>
                  <td className="ps-2 w-50">Kt</td>
                  <td className="w-50">0.00</td>
                </tr>
                <tr>
                  <td className="ps-2 w-50">Kt</td>
                  <td className="w-50">0.00</td>
                </tr>
                <tr>
                  <td className="ps-2 w-50">Kt</td>
                  <td className="w-50">0.00</td>
                </tr>
                <tr>
                  <td className="ps-2 w-50">Kt</td>
                  <td className="w-50">0.00</td>
                </tr>
              </tbody>
            </table>
          </Popover.Body>
        </Popover>
      }
      placement="bottom"
      trigger="click"
    >
      <Button variant="secondary">AL-5</Button>
    </OverlayTrigger>
  );
}

export default function Dashboard() {
  return (
    <Container className="dashboard d-flex flex-column">
      <FechaTurno
        leftContent={
          <>
            <Col lg={4} md={5} sm={6} xl={3} xs={12}>
              <div className="dashboard__inventario dashboard__inventario--total">
                <span className="fs-3">Inventario total</span>
                <p className="mb-1">210</p>
              </div>
            </Col>
            <Col lg={4} md={5} sm={6} xl={3} xs={12}>
              <div className="dashboard__inventario dashboard__inventario--principal px-3 py-2">
                <table className=" w-100">
                  <tbody>
                    <tr className="dashboard__inventario__table-row">
                      <td>Inventario Principal</td>
                      <td>125</td>
                    </tr>
                    <tr className="dashboard__inventario__table-row">
                      <td>Patio 13</td>
                      <td>25</td>
                    </tr>
                    <tr className="dashboard__inventario__table-row">
                      <td>Inventario SEC-B</td>
                      <td>60</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </>
        }
      />
      <Row>
        <Col xs={12} md={6}>
          {/* Grafica aqui */}
        </Col>
        <Col className="d-flex justify-content-around" xs={12} md={6}>
          <Alimentador />
          <Alimentador />
          <Alimentador />
          <Alimentador />
          <Alimentador />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>{/* Grafica aqui */}</Col>
      </Row>
    </Container>
  );
}
