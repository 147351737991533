import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../components/Loading';
import Title from '../components/Title';
import { ROUTES } from '../constants/routes';
import useAreasTrituracion from '../hooks/useAreasTrituracion';
import useError from '../hooks/useError';
import useUsuarios from '../hooks/useUsuarios';
import { TIPO_USUARIO } from '../models/enums/tipoUsuario.enum';
import { IRegistroUsuario } from '../models/interfaces/usuarios/IRegistroUsuario';
import { IRol, IUsuario, IUsuarioInterno } from '../models/interfaces/usuarios/IUsuario';
import {
  addUsuarioExternoService,
  addUsuarioIternoService,
  editUsuariosService,
  getRolesService,
  getUsuariosInternosService,
} from '../services/usuarios.service';
import { registroUsuarioValidationSchema } from '../utils/validations/registro-usuario.validation';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../styles/RegistroUsuario.scss';
import { limpiarAreasTrituracion } from '../utils/area-trituracion';

export default function RegistroUsuario() {
  const [seleccionado, setSeleccionado] = useState<IUsuarioInterno[]>([]);
  const [tipoUsuario, setTipoUsuario] = useState<string>('');
  const navigate = useNavigate();
  const { id, tipo } = useParams();
  const isEdit = !!id;
  const isInterno = tipo?.toLowerCase() === TIPO_USUARIO.INTERNO.toLowerCase();
  const { ErrorAlert, setError } = useError();
  const onError = e => setError(e);
  const { data: areas, isLoading: areasIsLoading } = useAreasTrituracion({
    enabled: !isInterno,
    onError,
    staleTime: Infinity,
  });
  const { data: roles } = useQuery(['roles'], getRolesService, {
    enabled: isInterno,
    onError,
    staleTime: Infinity,
  });
  const { data: usuarios, isLoading: areUsuariosLoading } = useUsuarios({ onError });
  const { data: usuariosInternos, isLoading: areInternalUsersLoading } = useQuery(
    ['usuarios-internos'],
    getUsuariosInternosService,
    {
      enabled: isInterno && !isEdit,
      onError,
    },
  );
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    isEdit ? editUsuariosService : isInterno ? addUsuarioIternoService : addUsuarioExternoService,
    {
      onError,
      onSuccess: () => {
        queryClient.invalidateQueries(['usuarios']);
        navigate(ROUTES.USUARIOS);
      },
    },
  );

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<IRegistroUsuario>({
    resolver: yupResolver(registroUsuarioValidationSchema(isInterno)),
  });

  const registrarUsuario = async (dataRequest: IRegistroUsuario) => {
    if (isInterno) {
      const request = {
        ...dataRequest,
        roles: dataRequest.roles.map(role => ({ name: role, selected: true })),
        area: dataRequest.areaId,
      };
      mutate(request);
    } else {
      const roles = isEdit
        ? []
        : [
            {
              name: 'UsrTrituracionSecundaria',
              selected: true,
            },
          ];
      const request = {
        ...dataRequest,
        roles,
        area: dataRequest.areaId,
      };
      mutate(request);
    }
  };

  const usuarioInternoSeleccionado = (usuario: IUsuarioInterno[]) => {
    setSeleccionado([...usuario]);
    if (!!usuario.length) {
      const [usrInterno] = usuario;
      setValue('userName', usrInterno.userName);
      setValue('email', usrInterno.email);
    }
  };

  const capitalizeTipo = () => (!!tipo ? tipo.charAt(0).toUpperCase().concat(tipo.slice(1)) : '');

  useEffect(() => {
    if (id && !areUsuariosLoading) {
      const detalleUsuario: IUsuario = usuarios.find(usuario => usuario.id === id);

      //i Se quitan los roles externos e internos de la lista
      const roles = [...detalleUsuario.roles]
        .filter((role: IRol) => role.name !== 'UsrTrituracionSecundaria')
        .map(item => item.name);

      setTipoUsuario(detalleUsuario.tipo.toLowerCase());
      reset({ ...detalleUsuario, roles });
    }
  }, [areUsuariosLoading, usuarios, id]);

  if (areUsuariosLoading || areasIsLoading) return <Loading mensaje="Cargando..." />;

  return (
    <Container className="registro-usuario d-flex flex-column">
      {areInternalUsersLoading && !isEdit && isInterno && <Loading mensaje="Cargando" />}
      <ErrorAlert />
      <Title
        ruta={ROUTES.USUARIOS}
        titulo={isEdit ? 'Editar Usuario' : `Registro de Usuario ${capitalizeTipo()}`}
      />
      <form className="mt-5" onSubmit={handleSubmit(registrarUsuario, e => console.log(e))}>
        {isEdit && <input type="hidden" {...register('id')} />}
        {isInterno && !isEdit && (
          <Row className="my-4">
            <Col className="offset-lg-3 offset-md-3" md={6} xs={12}>
              <Form.Group>
                <Form.Label htmlFor="single-typeahead">Buscar Usuario</Form.Label>
                <Typeahead
                  filterBy={['userName', 'nombre', 'email']}
                  id="single-typeahead"
                  labelKey="userName"
                  onChange={usuarioInternoSeleccionado}
                  options={usuariosInternos || []}
                  placeholder={`Usuario ${capitalizeTipo()}`}
                  renderMenuItemChildren={(option: IUsuarioInterno, { text }) => (
                    <div>
                      <Highlighter
                        highlightClassName="bg-transparent font-weight-bold p-0"
                        search={text}
                      >
                        {option.nombre}
                      </Highlighter>
                      <div>
                        <div>
                          <small>
                            Usuario:{' '}
                            <Highlighter
                              highlightClassName="bg-transparent font-weight-bold p-0"
                              search={text}
                            >
                              {option.userName}
                            </Highlighter>
                          </small>
                        </div>
                        <div>
                          <small>
                            Email:{' '}
                            <Highlighter
                              highlightClassName="bg-transparent font-weight-bold p-0"
                              search={text}
                            >
                              {option.email}
                            </Highlighter>
                          </small>
                        </div>
                      </div>
                    </div>
                  )}
                  selected={seleccionado}
                  clearButton
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="my-4">
          <Col className="offset-lg-3 offset-md-3" md={6} xs={12}>
            <Form.Group controlId="usuario">
              <Form.Label>
                Usuario<span className="registro-usuario__required">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.userName}
                placeholder="Usuario"
                {...register('userName')}
                readOnly={isInterno || isEdit}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {errors?.userName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="offset-lg-3 offset-md-3" md={6} xs={12}>
            <Form.Group controlId="email">
              <Form.Label>
                Correo Electronico
                <span className="registro-usuario__required">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.email}
                placeholder="Correo Electronico"
                {...register('email')}
                readOnly={isInterno || (tipoUsuario === 'interno' && isEdit)}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {isInterno && (
          <Row className="my-4">
            <Col className="offset-lg-3 offset-md-3" md={6} xs={12}>
              {roles?.length ? (
                <Form.Group controlId="roles">
                  <Form.Label className="m-0">
                    Roles<span className="registro-usuario__required">*</span>
                  </Form.Label>
                  {roles.map((role: IRol) => (
                    <Form.Check
                      key={role.id}
                      className="my-3"
                      id={role.name.toLowerCase()}
                      isInvalid={!!errors?.roles}
                      label={role.name}
                      type="checkbox"
                      value={role.name}
                      {...register('roles')}
                    />
                  ))}
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors?.roles?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : null}
              <p className="mt-4">
                Los campos con <span className="registro-usuario__required">*</span> son
                obligatorios.
              </p>
            </Col>
          </Row>
        )}
        {tipo?.toLowerCase() === TIPO_USUARIO.TRITURACION_SECUNDARIA.toLowerCase() && (
          <Row className="my-4">
            <Col className="offset-lg-3 offset-md-3" md={6} xs={12}>
              <Form.Group controlId="area-trituracion">
                <Form.Label className="m-0">
                  Area de Trituración<span className="registro-usuario__required">*</span>
                </Form.Label>
                <Form.Select isInvalid={!!errors.areaId} {...register('areaId')}>
                  <option value={0}>Seleccione área de trituración</option>
                  {limpiarAreasTrituracion(areas)?.map(({ id, descripcion }) => (
                    <option key={id} value={id}>
                      {descripcion}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors?.areaId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row className="my-4">
          <Col
            className="offset-lg-3 offset-md-3 my-0 d-flex align-items-center justify-content-center"
            md={6}
            xs={12}
          >
            <Button className="text-white w-75" type="submit" variant="secondary">
              Guardar Usuario {capitalizeTipo()}
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
