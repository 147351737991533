export const downloadFile = async (file: Blob) => {
  const linkReport = URL.createObjectURL(file);
  const linkElement = document.createElement('a');
  linkElement.setAttribute('href', linkReport);
  linkElement.setAttribute('download', 'TrituracionSecundaria.xlsx');

  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
  URL.revokeObjectURL(linkReport);
};
