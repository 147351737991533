import { GetMovTrituracionByFechaRequest } from '../models/interfaces/ITrituracionSecundaria/api-requests';
import {
  IColeccionTrituracionSecundaria,
  IEditarTrituracionSecundaria,
  IRegistroTrituracionSecundaria,
} from '../models/interfaces/ITrituracionSecundaria/IMovimientosTrituracionSecundaria';
import http from '../utils/http';

export const addMovimientoTrituracionSecundariaService = async (
  dataRequest: IRegistroTrituracionSecundaria,
) => {
  try {
    const response = await http.post(
      'MovimientosTrituracionSecundaria/RegistrarMovimientosTrituracionSecundaria',
      dataRequest,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editMovimientoTrituracionSecundariaService = async (dataRequest: {
  movimientos: IEditarTrituracionSecundaria[];
}) => {
  try {
    const response = await http.put(
      `MovimientosTrituracionSecundaria/EditarMovimientosTrituracionSecundaria`,
      dataRequest,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getMovimientoTrituracionSecundariaService = async () => {
  try {
    const response = await http.get(
      'MovimientosTrituracionSecundaria/ObtenerMovimientosTrituracionSecundaria',
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getByIdMovimientoTrituracionSecundariaService = async (idTrituracion: number) => {
  try {
    const response = await http.get(`Camion/ObtenerCamion/${idTrituracion}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteMovimientoTrituracionSecundariaService = async (idTrituracion: number) => {
  try {
    const response = await http.delete(
      `MovimientosTrituracionSecundaria/EliminarMovimientosTrituracionSecundaria/${idTrituracion}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getColeccionMovimientosTrituracion = async (
  params: GetMovTrituracionByFechaRequest,
) => {
  try {
    const { data } = await http.get<IColeccionTrituracionSecundaria[]>(
      'MovimientosTrituracionSecundaria/GetAllMovTriSecuByFechaTutnoId',
      { params },
    );
    return data;
  } catch (ex) {
    throw ex;
  }
};

export class MovimientoTrituracionSecundariaService {
  static async getByFechaTurno(params: GetMovTrituracionByFechaRequest) {
    try {
      const response = await http.get(
        'MovimientosTrituracionSecundaria/GetMovTriSecuByFechaTutnoId',
        { params },
      );
      return response?.data;
    } catch (ex) {
      throw ex;
    }
  }
}
