import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import FechaTurno from '../../components/FechaTurno';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import useCamiones from '../../hooks/useCamiones';
import useDepositoMineral from '../../hooks/useDepositoMineral';
import useError from '../../hooks/useError';
import useFechaTurno from '../../hooks/useFechaTurno';
import useViajes from '../../hooks/useVIajes';
import { ICamiones } from '../../models/interfaces/catalogos/ICatalogos';
import {
  IViajeCamionTrayecto,
  IViajesCamionesTrayectos,
} from '../../models/interfaces/movimientoMineral/IMovimientoMineral';
import { IRegistroTrayectos } from '../../models/interfaces/movimientoMineral/ITrayectos';
import { addViajesCamionesService } from '../../services/viajesCamion.service';
import { registroViajeCamionTrayectoSchema } from '../../utils/validations/registro-viajesCamionTrayecto.validation';

import '../../styles/RegistrosViajes.scss';

const tdWidth: number = 200;

export default function RegistroViajes() {
  const { ErrorAlert, setError } = useError();
  const { fecha, turno } = useFechaTurno();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    resolver: yupResolver(registroViajeCamionTrayectoSchema),
  });

  const {
    data: viajes,
    isLoading,
    refetch,
    isFetching,
  } = useViajes({ fecha, turnoId: turno, options: { refetchOnWindowFocus: false } });

  const {
    control,
    register: fieldArrayRegister,
    setValue: setValueFieldArray,
    getValues: getValueFieldArray,
  } = useForm<{
    movimientos: IViajeCamionTrayecto[];
  }>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'movimientos',
  });
  const formValues = useWatch({
    control,
  });

  const { mutate: mutateAddViajes, isLoading: addViajesIsLoading } = useMutation(
    addViajesCamionesService,
    {
      onError: e => {
        setError(e);
      },
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { data: depositos } = useDepositoMineral({
    onError: e => setError(e),
  });

  const { data: camiones } = useCamiones({
    onError: e => setError(e),
  });

  const camionesPropios = useMemo(() => {
    if (!!!camiones?.length) return [];

    return camiones.filter((cam: ICamiones) => cam.areaTrituracionEntityId === 3);
  }, [camiones]);

  const getEsExitenteOrigenDestino = (origenId: number, destinoId: number) =>
    formValues?.movimientos?.some(m => m.origenId === origenId && m.destinoId === destinoId);

  const onSubmitTrayecto = async (requestData: IRegistroTrayectos) => {
    const mismoOrigenDestino = requestData.origenTrayectoId === requestData.destinoTrayectoId;
    const existenteConMismoOrigenDestino = getEsExitenteOrigenDestino(
      requestData.origenTrayectoId,
      requestData.destinoTrayectoId,
    );
    if (mismoOrigenDestino) setError({ message: 'Es el mismo origen y destino' });

    if (existenteConMismoOrigenDestino)
      setError({ message: 'Ese origen y destino ya fue agregado' });

    if (mismoOrigenDestino || existenteConMismoOrigenDestino) return;

    camionesPropios?.forEach(camion => {
      append({
        camionId: camion.id,
        destinoId: requestData.destinoTrayectoId,
        origenId: requestData.origenTrayectoId,
        cantidadViajes: 0,
      });
    });

    reset({
      origenTrayectoId: 0,
      destinoTrayectoId: 0,
    });
  };

  const onSubmitMovMin = async () => {
    if (!!formValues?.movimientos?.length) {
      const requestData: IViajesCamionesTrayectos = {
        fecha,
        turnoId: turno,
        viajes: formValues.movimientos as IViajeCamionTrayecto[],
      };
      mutateAddViajes(requestData);
    }
  };

  const findIndexFormValues = (camionId: number, origenId: number, destinoId: number) => {
    const index = fields?.findIndex(
      m => m.camionId === camionId && m.origenId === origenId && m.destinoId === destinoId,
    );
    return index;
  };

  const findDepositoDescripcion = (depositoId: number) => {
    const deposito = depositos?.find(deposito => deposito.id === +depositoId);
    return deposito?.descripcion;
  };

  const removeViaje = (origenId: number, destinoId: number) => {
    let indexes: number[] = [];
    fields.forEach((field, index) => {
      if (field.origenId === origenId && field.destinoId === destinoId) indexes.push(index);
    });
    remove(indexes);
  };

  const updateOrigenViaje = (nuevoOrigen: any, viejoOrigenId: number, destinoId: number) => {
    const mismoOrigenDestino = nuevoOrigen === destinoId;
    const hayMismoOrigenDestino = getEsExitenteOrigenDestino(nuevoOrigen, destinoId);

    if (mismoOrigenDestino) setError({ message: 'Es el mismo origen y destino' });

    if (hayMismoOrigenDestino) setError({ message: 'Ese origen y destino ya fue agregado' });

    if (mismoOrigenDestino || hayMismoOrigenDestino) return;

    fields.forEach((field, index) => {
      if (field.origenId === viejoOrigenId && field.destinoId === destinoId)
        setValueFieldArray(`movimientos.${index}.origenId`, nuevoOrigen);
    });
  };

  const getValorOrigen = (origenId, destinoId) => {
    const firstIndex = fields.findIndex(f => f.origenId === origenId && f.destinoId === destinoId);
    if (firstIndex >= 0) {
      const valor = getValueFieldArray(`movimientos.${firstIndex}.origenId`);
      return valor;
    }
    return undefined;
  };

  useEffect(() => {
    replace([]);
  }, [fecha, turno]);

  return (
    <Container className="movimiento-mineral d-flex flex-column" fluid>
      <Title titulo="REGISTRO DE VIAJES" />
      <div>
        <FechaTurno />
        <ErrorAlert />
      </div>
      <Row>
        <Col className="overflow-auto p-0 m-0">
          <Table className="mx-auto" bordered hover>
            <thead>
              <tr>
                <th className="camion registros-viajes__sticky-cell text-white">Origen-Destino</th>
                {camionesPropios &&
                  camionesPropios.map((cam: ICamiones) => (
                    <th key={cam.id} className="secondary-th">
                      {cam.numCamion}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {!!viajes?.length
                ? viajes.map((viaje, index) => (
                    <tr key={index}>
                      <td
                        className="registros-viajes__sticky-cell bg-white"
                        style={{
                          minWidth: tdWidth,
                        }}
                      >
                        {viaje.origen} - {viaje.destino}
                      </td>
                      {camionesPropios?.map(camion => (
                        <td
                          key={`${viaje.origen}-${viaje.destino}-${camion.numCamion}`}
                          style={{ minWidth: tdWidth }}
                        >
                          {viaje.viajesCamion.find(c => c.camion === camion.numCamion)?.viajes}
                        </td>
                      ))}
                    </tr>
                  ))
                : !!fields?.length &&
                  fields
                    .filter(
                      (value, index, current_value) =>
                        current_value.findIndex(
                          c => c.origenId === value.origenId && c.destinoId === value.destinoId,
                        ) === index,
                    )
                    .map(field => (
                      <tr key={field.id}>
                        <td
                          className="registros-viajes__sticky-cell bg-white"
                          style={{ minWidth: tdWidth }}
                        >
                          <Row>
                            <Col xs={9}>
                              <Form.Select
                                onChange={({ target }) =>
                                  updateOrigenViaje(target.value, field.origenId, field.destinoId)
                                }
                                value={getValorOrigen(field.origenId, field.destinoId)}
                              >
                                {depositos &&
                                  depositos?.map(({ id, descripcion }) => (
                                    <option key={id} value={id}>
                                      {descripcion}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Col>
                            <Col xs={1} />
                            <Col xs={12}>-</Col>
                            <Col className="ps-3" xs={9}>
                              {findDepositoDescripcion(field.destinoId)}
                            </Col>

                            <Col xs={1}>
                              <IconButton
                                onClick={() => removeViaje(field.origenId, field.destinoId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Col>
                          </Row>
                        </td>
                        {camionesPropios &&
                          Array.from(camionesPropios)
                            .filter(
                              (cam: ICamiones) =>
                                findIndexFormValues(cam.id, field.origenId, field.destinoId) >= 0,
                            )
                            .map((camion: ICamiones, i) => (
                              <td key={i} style={{ minWidth: tdWidth }}>
                                <Form.Group className="text-left p-2" controlId="cantidadViajes">
                                  <Form.Control
                                    isInvalid={!!errors.cantidadViajes}
                                    placeholder="Ingresa los viajes"
                                    type="number"
                                    {...fieldArrayRegister(
                                      `movimientos.${findIndexFormValues(
                                        camion.id,
                                        field.origenId,
                                        field.destinoId,
                                      )}.cantidadViajes`,
                                    )}
                                    onFocus={e => e.currentTarget.select()}
                                  />
                                </Form.Group>
                              </td>
                            ))}
                      </tr>
                    ))}
              {!!!viajes?.length && (
                <tr>
                  <td
                    className="registros-viajes__sticky-cell bg-white"
                    style={{ minWidth: tdWidth }}
                  >
                    <form onSubmit={handleSubmit(onSubmitTrayecto)}>
                      <Row>
                        <Col xs={9}>
                          <Form.Select {...register('origenTrayectoId')}>
                            <option value={0}>Origen</option>
                            {depositos &&
                              depositos?.map(({ id, descripcion }) => (
                                <option key={id} value={id}>
                                  {descripcion}
                                </option>
                              ))}
                          </Form.Select>
                          -
                          <Form.Select {...register('destinoTrayectoId')}>
                            <option value={0}>Destino</option>
                            {depositos &&
                              depositos?.map(({ id, descripcion }) => (
                                <option key={id} value={id}>
                                  {descripcion}
                                </option>
                              ))}
                          </Form.Select>
                        </Col>
                        <Col xs={1}>
                          <IconButton type="submit">
                            <AddCircleIcon />
                          </IconButton>
                        </Col>
                      </Row>
                    </form>
                  </td>
                  {camionesPropios &&
                    Array.from(camionesPropios).map((_, i) => (
                      <td key={i} style={{ minWidth: tdWidth }}>
                        <Form.Control placeholder="Ingresa los viajes" type="number" disabled />
                      </td>
                    ))}
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!!!viajes?.length && (
        <div className="d-flex flex-row-reverse m-2">
          <Button className="text-white" onClick={onSubmitMovMin} variant="secondary">
            Guardar Registro
          </Button>
        </div>
      )}
      {(isLoading || addViajesIsLoading || isFetching) && <Loading mensaje="Cargando viajes..." />}
    </Container>
  );
}
