import AvatarPlaceholderImage from '../assets/images/avatar-placeholder.png';
import { NavItemProps } from '../models/types/nav-item.type';

import '../styles/components/NavItem.component.scss';

export default function NavItem(props: NavItemProps) {
  const { usuario, role, avatar } = props;

  return (
    <div className="nav-item">
      <img
        className="nav-item__avatar"
        src={!!avatar ? avatar : AvatarPlaceholderImage}
        alt={!!avatar ? usuario : 'Usuario'}
      />
      <div className="nav-item__info">
        <p className="nav-item__texto">{usuario}</p>
        <p className="nav-item__texto--role">
          {!!role ? role.charAt(0).toUpperCase().concat(role.slice(1)) : ''}
        </p>
      </div>
    </div>
  );
}
