import { ICamiones, IRegistroCamion } from '../models/interfaces/catalogos/ICatalogos';
import { IEditarCantidadesTrayectosViajes } from '../models/interfaces/viajes/IViajes';
import http from '../utils/http';

export const addTrayectoCamionService = async (dataRequest: IRegistroCamion) => {
  try {
    const response = await http.post('ViajesCamionTrayecto/RegistrarTrayectoCamion', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editTrayectoCamionService = async (dataRequest: IRegistroCamion, idCamion: string) => {
  try {
    const response = await http.put(
      `ViajesCamionTrayecto/EditarTrayectoCamion/${idCamion}`,
      dataRequest,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const putTrayectosViajesService = async (dataRequest: IEditarCantidadesTrayectosViajes) => {
  try {
    await http.put(
      'ViajesCamionTrayecto/PutTrayectosViajes',
      dataRequest,
    );
  } catch (ex) {
    throw ex;
  }
};

export const getTrayectoCamionService = async () => {
  try {
    const response = await http.get('ViajesCamionTrayecto/ObtenerTrayectoCamion');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteTrayectoCamionService = async (dataRequest: ICamiones) => {
  try {
    const response = await http.delete(
      `ViajesCamionTrayecto/EliminarTrayectoCamion/${dataRequest.id}`,
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
