import { useQuery } from '@tanstack/react-query';

import { getCamionService } from '../services/camiones.service';

const useCamiones = options => {
  return useQuery(['camiones'], getCamionService, {
    staleTime: Infinity,
    ...options,
  });
};

export default useCamiones;
