import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Modal } from 'react-bootstrap';

import { ConfirmacionModalType } from '../../models/types/modal.type';

export default function ConfirmacionModal({
  confirmationMessage,
  handleClose,
  handleCloseSuccess,
  mutationParameter,
  mutationFn,
  afterMutationInvalidateQueryKey,
  title,
}: ConfirmacionModalType) {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation({
    mutationFn,
    onSuccess: () => {
      if(afterMutationInvalidateQueryKey)
        queryClient.invalidateQueries([afterMutationInvalidateQueryKey]);
    
      if (handleCloseSuccess)
        handleCloseSuccess();
      else 
        handleClose();
    },
  });

  const onConfirm = () => {
    mutate(mutationParameter);
  };

  return (
    <Modal backdrop={isLoading ? 'static' : true} show={!!mutationParameter} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{confirmationMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-light border-dark" onClick={handleClose}>
          Cerrar
        </Button>
        <Button disabled={isLoading} className="btn-primary" onClick={onConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}