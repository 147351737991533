import { Container } from 'react-bootstrap';

import Title from '../../components/Title';
import useData from '../../hooks/useData';
import useError from '../../hooks/useError';
import RegistroTrituracionSecundaria from './RegistroTrituracionSecundaria';

export default function Registros() {
  const { ErrorAlert, setError } = useError();
  const {
    dataUser: { area },
  } = useData();

  return (
    <Container className="usuarios d-flex flex-column">
      <Title titulo={`Registro de Toneladas ${area || ''}`} />
      <ErrorAlert />
      <RegistroTrituracionSecundaria setError={setError} />
    </Container>
  );
}
