import { Col, Container, Row, Table } from 'react-bootstrap';

import FechaTurno from '../components/FechaTurno';
import '../styles/Calidad.scss';

const calidad = [
  {
    id: 1,
    numero: '76',
    voladura: 'Gravilla',
    femag: '21.3',
    fetot: '67.3',
    silice: '2.72',
    azufre: '0.05',
    calcio: '0.88',
    fosforo: '0.010',
    mgo: '0.39',
    al2o3: '0.80',
    kwh: '7.1',
    tondia: '',
  },
  {
    id: 2,
    numero: '66',
    voladura: 'Patio 07',
    femag: '19.0',
    fetot: '66.8',
    silice: '3.13',
    azufre: '0.07',
    calcio: '0.82',
    fosforo: '0.010',
    mgo: '0.45',
    al2o3: '1.06',
    kwh: '8.7',
    tondia: '',
  },
  {
    id: 3,
    numero: '90',
    voladura: 'F5A143A',
    femag: '18.1',
    fetot: '66.8',
    silice: '2.54',
    azufre: '0.07',
    calcio: '1.09',
    fosforo: '0.030',
    mgo: '0.30',
    al2o3: '0.94',
    kwh: '8.7',
    tondia: '',
  },
  {
    id: 4,
    numero: '91',
    voladura: 'F5A267A',
    femag: '18.3',
    fetot: '67.2',
    silice: '2.89',
    azufre: '0.06',
    calcio: '0.85',
    fosforo: '0.020',
    mgo: '0.36',
    al2o3: '1.04',
    kwh: '10.4',
    tondia: '',
  },
];

export default function Calidad() {
  return (
    <Container className="calidad d-flex flex-column" fluid>
      <FechaTurno />
      <Row>
        <Col>
          <Table bordered>
            <thead>
              <tr className="primary-header">
                <th colSpan={12}>Calidad alimentada a molienda primaria</th>
              </tr>
              <tr className="real">
                <th>Real</th>
                <th>Total</th>
                <th>19.28</th>
                <th>66.21</th>
                <th>3.40</th>
                <th>0.106</th>
                <th>0.95</th>
                <th>0.011</th>
                <th>0</th>
                <th>0</th>
                <th>0</th>
                <th>0</th>
              </tr>
              <tr className="plan">
                <th>Plan</th>
                <th>Total</th>
                <th>19.84</th>
                <th>67.18</th>
                <th>2.86</th>
                <th>0.1146</th>
                <th>0.84</th>
                <th>0.014</th>
                <th>0.4</th>
                <th>0.9</th>
                <th>8.88</th>
                <th>38320.14</th>
              </tr>
              <tr>
                <th>No</th>
                <th>Voladura</th>
                <th>%FeMag</th>
                <th>FeTot</th>
                <th>Sílice</th>
                <th>Azufre</th>
                <th>Calcio</th>
                <th>Fósforo</th>
                <th>MgO</th>
                <th>AL2O3</th>
                <th>kwh/t Mol Aut</th>
                <th>Ton/día</th>
              </tr>
            </thead>
            <tbody>
              {calidad.map(c => (
                <tr key={c.id}>
                  <td>{c.numero}</td>
                  <td>{c.voladura}</td>
                  <td>{c.femag}</td>
                  <td>{c.fetot}</td>
                  <td>{c.silice}</td>
                  <td>{c.azufre}</td>
                  <td>{c.calcio}</td>
                  <td>{c.fosforo}</td>
                  <td>{c.mgo}</td>
                  <td>{c.al2o3}</td>
                  <td>{c.kwh}</td>
                  <td>{c.tondia}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
